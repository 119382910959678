import styled, { css } from 'styled-components';

import { $MenuCountBadge, $MenuIconContainer } from '../../../Menu/Menu.styles';

export const $MinimalHeaderSidebar = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.newUI?.defaultColors?.menu};

  display: flex;
  flex-direction: column;
`;

const badgeHover = css`
  &:hover ${$MenuIconContainer} {
    svg {
      transform: translate(0, 0) !important;
    }
    ${$MenuCountBadge} {
      opacity: 0 !important;
    }
  }
`;

export const $MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.systemColors.secondaryBackground};
  flex: 1;
  padding: 16px 8px 24px 16px;
  gap: 8px;
`;

export const $MenuItem = styled.div<{
  $hideCountOnHover: boolean;
  $isActive?: boolean;
}>`
  width: 100%;
  position: relative;
  ${({ $hideCountOnHover }) => ($hideCountOnHover ? badgeHover : null)};
  white-space: nowrap;
`;

export const $Header = styled.div`
  display: flex;
  padding: 0 1rem 0 0;
  background: ${({ theme }) => theme.newUI?.defaultColors?.menu};
`;
