import React from 'react';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import messages from './messages';
import {
  $NoResultsContainer,
  $NoResultsDescription,
  $NoResultsTitle,
  $NoResultsWrapper,
} from './SearchResult.styles';

const NoSearchResult = () => {
  return (
    <$NoResultsWrapper>
      <$NoResultsContainer>
        <$NoResultsTitle>
          <SafeFormattedMessageWithoutSpread
            message={messages.noResultsTitle}
          />
        </$NoResultsTitle>
        <$NoResultsDescription>
          <SafeFormattedMessageWithoutSpread
            message={messages.noResultsDescription}
          />
        </$NoResultsDescription>
      </$NoResultsContainer>
    </$NoResultsWrapper>
  );
};

export default NoSearchResult;
