import { CURRENCIES } from '@savgroup-front-common/constants';

import { MARKETING_OFFER_TYPE } from './CommercialSolution';

export interface RefundPolicyValue {
  orderLineReference: string;
  price: number;
  priceCurrencyCode: CURRENCIES;
  isSelected?: boolean;
}

export interface RefundPolicy {
  products: RefundPolicyValue[];
  services: RefundPolicyValue[];
  other: RefundPolicyValue[];
  shippingPrice: {
    price: number;
    priceCurrencyCode: CURRENCIES;
    isSelected?: boolean;
  };
  refundMethod: MARKETING_OFFER_TYPE;
  limitAmount?: number;
  limitAmountByPercentage?: number;
  deductibleHandlingPrice?: {
    price: number;
    priceCurrencyCode: CURRENCIES;
    isSelected?: boolean;
    percentageValue?: number;
  };
}

export enum REFUND_POLICY_TYPE {
  NOT_SET = 'NotSet',
  COMMERCIAL_GESTURE = 'CommercialGesture',
  REFUND = 'Refund',
}
