import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  RequestLineStatus,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requestLines/bulkAvailableActions`;

export interface RequestLineBulkActions {
  possibleNewStatuses: RequestLineStatus[];
}

const getRequestLineBulkActionsQuery = async ({
  requestLineIds,
}: {
  requestLineIds: string[];
}): Promise<BackResponse<RequestLineBulkActions> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<RequestLineBulkActions>>(
      buildUrl(ENDPOINT, { requestLineIds }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getRequestLineBulkActionsQuery;
