import { defineMessages } from 'react-intl';

export default defineMessages({
  getStarted: {
    id: 'view.choosePricing.planPreview.getStarted',
    defaultMessage: 'Get started',
  },
  isActive: {
    id: 'view.choosePricing.planPreview.isActive',
    defaultMessage: 'Active',
  },
});
