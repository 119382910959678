import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import {
  APP_ENVS,
  currentAppEnvironment,
} from '@savgroup-front-common/constants/src/shared';

import rootReducer from '../../domains/rootReducer';

export default function configureStore({ routerMiddleware, routerReducer }) {
  const initialState = {};

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, routerMiddleware, thunk];

  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });
  const devMiddlewares =
    import.meta.env.VITE_ENABLE_REDUX_LOGGER === 'true'
      ? middlewares.concat(logger)
      : middlewares;

  const composedEnhancer =
    currentAppEnvironment === APP_ENVS.PROD
      ? applyMiddleware(...middlewares)
      : composeWithDevTools(applyMiddleware(...devMiddlewares));

  const store = createStore(
    rootReducer(routerReducer),
    initialState,
    composedEnhancer,
  );

  return { store, runSaga: sagaMiddleware.run };
}
