import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { ExportFilteredFilesCommandArgs } from './exportFilteredFilesCommand';

const ENDPOINT = `${APIConfiguration.search}export/filteredTodos`;

async function exportFilteredTodosCommand({
  exportFormat,
  query,
  filters,
}: ExportFilteredFilesCommandArgs): Promise<
  BackResponse<string> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        query,
        filters,
        exportFormat,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default exportFilteredTodosCommand;
