import React, { FunctionComponent } from 'react';

import Icon from './Icon';
import { IconPropsInterface } from './Icon.types';

interface NewReversLogoIconProps extends IconPropsInterface {
  dotColor?: string;
}

const NewReversLogoIcon: FunctionComponent<NewReversLogoIconProps> = ({
  color = 'white',
  size = '24px',
  dotColor = 'white',
}) => (
  <Icon size={size} viewBox="0 0 200 200" color={color}>
    <path
      d="M167 135C172.5 135 177.167 137 181 141C185 144.833 187 149.5 187 155C187 160.5 185 165.25 181 169.25C177.167 173.083 172.5 175 167 175C161.5 175 156.75 173.083 152.75 169.25C148.917 165.25 147 160.5 147 155C147 149.5 148.917 144.833 152.75 141C156.75 137 161.5 135 167 135Z"
      fill={dotColor}
    />
    <path
      d="M72.3009 25C79.1321 25 85.818 25.9378 92.3586 27.8134C98.8991 29.6891 104.786 32.5746 110.018 36.4701C115.396 40.3657 119.611 45.2711 122.663 51.1866C125.861 56.9577 127.459 63.8831 127.459 71.9627C127.459 80.4751 125.279 87.8333 120.919 94.0373C116.704 100.097 111.108 104.858 104.132 108.321C97.155 111.639 89.597 113.587 81.4576 114.164L134 170H94.1027L43.3045 110.701V170H13V25H72.3009ZM43.3045 53.1343V89.7089C46.7928 90.8632 50.6444 91.801 54.8595 92.5224C59.2198 93.0995 63.5802 93.3881 67.9405 93.3881C76.6613 93.3881 83.5652 91.6567 88.6523 88.194C93.8847 84.5871 96.5009 79.5373 96.5009 73.0448C96.5009 65.9751 93.7393 60.9254 88.2162 57.8955C82.8384 54.7214 76.2252 53.1343 68.3766 53.1343H43.3045Z"
      fill="currentColor"
    />
  </Icon>
);

export default NewReversLogoIcon;
