import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { $Icon, $MenuNavigationItem } from '../Menu.styles';
import { MenuItem } from '../Menu.types';

interface MenuListItemProps {
  menu: MenuItem;
}

export const MenuListItem: FC<MenuListItemProps> = ({ menu }) => {
  const isActive = useMatch(menu.path);

  const Icon = menu.icon as any;

  return (
    <$MenuNavigationItem
      $isActive={!!isActive}
      href={menu.to}
      isExternal={menu.isExternal}
    >
      {Icon && (
        <$Icon>
          <Icon />
        </$Icon>
      )}
      <SafeFormattedMessageWithoutSpread message={menu.message} />
    </$MenuNavigationItem>
  );
};
