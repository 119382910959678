import currentUserFiltersQuery from './currentUserFiltersQuery';
import deleteFiltersPreset from './deleteFiltersPreset';
import exportFilteredFilesCommand from './exportFilteredFilesCommand';
import exportFilteredTodosCommand from './exportFilteredTodosCommand';
import filterFilesCommand from './filterFilesCommand';
import filterTodosCommand from './filterTodosCommand';
import getExportedFileQuery from './getExportedFileQuery';
import getExportJobQuery from './getExportJobQuery';
import getFilesByQuery from './getFilesByQuery';
import getFiltersFacets from './getFiltersFacets';
import setFiltersPreset from './setFiltersPreset';

export const SearchService = {
  deleteFiltersPreset,
  getFilesByQuery,
  getFiltersFacets,
  currentUserFiltersQuery,
  setFiltersPreset,
  filterFilesCommand,
  filterTodosCommand,
  exportFilteredFilesCommand,
  exportFilteredTodosCommand,
  getExportJobQuery,
  getExportedFileQuery,
};
