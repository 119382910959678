import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { LANGUAGES } from '@savgroup-front-common/constants';
import {
  changeLocaleLanguage,
  setUserLanguagePreference,
} from '@savgroup-front-common/core/src/domains/i18n/actions';

import { useAuth } from '../../../../../app/login/AuthProviderContext';

const useProfileActions = () => {
  const { logout: signout } = useAuth();
  const dispatch = useDispatch();

  const updateIntl = useCallback(
    (locale: LANGUAGES) => {
      dispatch(setUserLanguagePreference({ locale }));
      dispatch(changeLocaleLanguage({ locale }));
    },
    [dispatch],
  );
  const logout = useCallback(async () => {
    return signout();
  }, [signout]);

  return {
    logout,
    updateIntl,
  };
};

export default useProfileActions;
