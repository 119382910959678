import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { rem } from '@savgroup-front-common/core/src/helpers';

export const $DocumentData = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;
export const $DocumentName = styled.div`
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  font-size: ${({ theme }) => theme.fonts.size.medium};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
`;

export const $DocumentInfo = styled.div`
  min-height: ${rem(18)};
  color: ${({ theme }) => theme.systemColors.defaultTextColor};
  font-size: ${({ theme }) => theme.fonts.size.small};
  display: flex;
  align-items: center;
  > div:first-child:not(:last-child) {
    padding-top: ${rem(2)};
    padding-right: ${rem(4)};
  }

  & svg {
    color: ${({ theme }) => theme.systemColors.defaultTextColor};
  }
`;

export const $DocumentBarRow = styled(Row)`
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.systemColors.defaultBackground};
`;

export const $DocumentBarCol = styled(Col)`
  position: relative;
`;
