import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { Property } from '@savgroup-front-common/types/src/Onboarding/Carrier';

const ENDPOINT = `${APIConfiguration.carrier}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/carriers/genericAccount/definitions`;

export interface GenericCarrierAccountDefinitionReturnValues {
  carrierUniqueName: string;
  hasAccount: boolean;
  displayOrder: {
    groupName: string;
    properties: string[];
  }[];
  geographicalAreas: string[];
  hasReversContract: boolean;
  instructionsUrl: string;
  parcelType: string;
  properties: Property[];
}

interface GetFileShippingsQueryArgs {
  sellerId: string;
}

const getGenericCarrierAccountsDefinitionsQuery = async ({
  sellerId,
}: GetFileShippingsQueryArgs): Promise<
  | BackResponse<GenericCarrierAccountDefinitionReturnValues[]>
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    const response = await apiCall<
      BackResponse<GenericCarrierAccountDefinitionReturnValues[]>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getGenericCarrierAccountsDefinitionsQuery;
