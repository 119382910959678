import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { CIVILITY } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';
import { CarrierAddress } from '@savgroup-front-common/types/src/CarrierAddress';

const ENDPOINT = `${APIConfiguration.carrier}label/verify`;

interface VerifyAddressArgs {
  carrierCompany?: string;
  address: CarrierAddress;
  verifyStrict: boolean;
}
export interface VerifyAddressResponseValue {
  city: string;
  civility: CIVILITY;
  country: string;
  countryCode: string;
  name1: string;
  name2: string;
  phone: string;
  state: string;
  street1: string;
  street2: string;
  zipCode: string;
}

async function verifyAddress({
  carrierCompany,
  address,
  verifyStrict,
}: VerifyAddressArgs): Promise<
  BackResponse<VerifyAddressResponseValue> | BackResponseFailure
> {
  try {
    const response = await apiCall<BackResponse<VerifyAddressResponseValue>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          carrierCompany,
          address,
          verifyStrict,
        },
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default verifyAddress;
