import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { UserConfiguration } from '../../../domains/userManagement';
import { ImportLevel } from '../Upload/getAvailableImportTypesQuery';

// eslint-disable-next-line max-len
const ENDPOINT = `${APIConfiguration.import}tenants/${COMMON_QUERY_PARAMS.TENANT_ID}/importType/${COMMON_QUERY_PARAMS.IMPORT_TYPE}/importLevel`;

export const upsertImportLevelConfigurationCommand = async ({
  tenantId,
  importType,
  importLevel,
}: {
  tenantId: string;
  importType: string;
  importLevel: ImportLevel;
}): Promise<BackResponse<UserConfiguration[]> | BackResponseFailure> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.TENANT_ID, tenantId).replace(
        COMMON_QUERY_PARAMS.IMPORT_TYPE,
        importType,
      ),
    );

    return await apiCall<BackResponse<UserConfiguration[]>>(url, {
      method: SUPPORTED_METHODS.PUT,
      json: { importLevel },
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};
