import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/carriers/genericAccount/carrierName`;

interface UpsertGenericCarrierAccountForUpsCommandReturnValues {
  id: string;
  carrier: string;
  name: string;
  description: string;
  standardProductCode: string;
  frToNoFrProductCode: string;
  noFrToFrProductCode: string;
  countryCode: string;
  warrantyId: string;
  siteType: string;
  siteId: string;
}

interface UpsertGenericCarrierAccountForUpsCommandArgs {
  sellerId: string;
  carrierUniqueName: string;
  data: any;
}

const upsertGenericCarrierAccountForUpsCommand = async ({
  sellerId,
  carrierUniqueName,
  data,
}: UpsertGenericCarrierAccountForUpsCommandArgs): Promise<
  | BackResponse<UpsertGenericCarrierAccountForUpsCommandReturnValues>
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace('carrierName', carrierUniqueName.toLowerCase());

    const response = await apiCall<
      BackResponse<UpsertGenericCarrierAccountForUpsCommandReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.POST,
      json: data,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default upsertGenericCarrierAccountForUpsCommand;
