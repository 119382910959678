import styled from 'styled-components';

import {
  media,
  Z_INDEX_HEADER,
} from '@savgroup-front-common/constants/src/shared';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $AppHeaderContainer = styled('header')<{
  $isNewUiEnabled: boolean;
}>`
  background-color: ${({ theme, $isNewUiEnabled }) =>
    $isNewUiEnabled ? theme.newUI?.defaultColors?.menu : theme.colors.white};

  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  justify-content: space-between;

  box-shadow: 6px 6px 9px 0 rgba(147, 147, 147, 0.09);
  max-height: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? rem(48) : rem(50))};
  margin: 0;
  padding: 0;
  height: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? rem(48) : rem(50))};
  z-index: ${Z_INDEX_HEADER};
  position: relative;

  @media ${media.minWidth.lg} {
    height: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? null : rem(70))};
    max-height: ${({ $isNewUiEnabled }) =>
      $isNewUiEnabled ? 'initial' : rem(70)};
  }
`;

export const $HeaderPortal = styled.div`
  flex: 1;
`;
export const $SellerSelectPortal = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
`;

export const $NavigationMenuWrapper = styled.div`
  flex: 1;
`;

export const $HeaderLogo = styled.div`
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
`;

export const $HeaderSearchBar = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 0.8rem;

  [class*='SearchInputstyles__SearchInput'] {
    color: ${({ theme }) => theme.colors.mainTextColor};

    &::placeholder {
      color: ${({ theme }) => theme.colors.paragraphTextColor};
    }

    svg {
      fill: ${({ theme }) => theme.colors.mainTextColor};
    }

    &:focus {
      svg {
        fill: ${({ theme }) => theme.colors.mainTextColor};
      }
    }
  }
`;
