import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  RequestLineStatus,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}dispatch/actions/${COMMON_QUERY_PARAMS.DISPATCH_ID}`;

const getDispatchActions = async ({
  dispatchId,
}: {
  dispatchId: string;
}): Promise<BackResponse<RequestLineStatus[]> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<RequestLineStatus[]>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.DISPATCH_ID, dispatchId),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getDispatchActions;
