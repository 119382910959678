import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BaseBackResponse,
  DeliveryLocationType,
  QuotationState,
  RecipientType,
  ReverseMoney,
  ReverseMoneyNumberAmount,
  SparePartCondition,
} from '@savgroup-front-common/types';

import { QuotationTypes } from '../updateQuotation/updateQuotation';

const ENDPOINT = `${APIConfiguration.repairer}files/${COMMON_QUERY_PARAMS.FILE_ID}/quotation`;

export interface QuotationSparePartSelectionDto {
  sparePartSupplierAssociationId: string;
  supplierReference?: string;
  supplierId: string;
  preTaxUnitBuyingPrice: ReverseMoneyNumberAmount;
  preTaxUnitPrice: ReverseMoneyNumberAmount;
  quantity: number;
  amountIncludedTax: ReverseMoneyNumberAmount;
  requestLineId: string;
  condition?: SparePartCondition;
  deliveryAddress?: AddressInfoDto;
  deliveryLocationType?: DeliveryLocationType;
  deliveryLocationActorId?: string;
  minDeliveryDays?: number;
  maxDeliveryDays?: number;
  stockName?: string;
}

export interface QuotationRecipientDto {
  id?: string;
  fullName?: string;
  address?: AddressInfoDto;
  phone?: string;
  email?: string;
  recipientType: RecipientType;
}

interface SetQuotationArgs {
  fileId: string;
  sellerId: string;
  description: string;
  spareParts: {
    id: string;
    sparePartId: string;
    supplierId: string;
    description?: string;
    isRequiredForRepair?: boolean;
    isSelectedForRepair?: boolean;
    recipient?: QuotationRecipientDto;
    sparePart?: QuotationSparePartSelectionDto;
  }[];
  unknownSparePartsFromBuyingPrice: {
    description: string;
    reference: string;
    supplierId?: string;
    preTaxBuyingPrice: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  unknownSparePartsFromSellingPrice: {
    description: string;
    reference: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  services: {
    id: string;
  }[];
  discounts: {
    description: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  unknownServices: {
    description: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  administrativeFees: {
    description: string;
    priceIncludedTax: ReverseMoney;
    recipient?: QuotationRecipientDto;
  }[];
  state: QuotationState.DRAFT | QuotationState.PENDING_CUSTOMER_APPROVAL;
  isBonusRemoved?: boolean;
}
export interface SetQuotationInternalArgs extends SetQuotationArgs {
  type: 'Internal';
}
export interface SetQuotationWithSubcontractorArgs extends SetQuotationArgs {
  subcontractorQuoteNumber: string;
  type: 'Subcontractor';
  destructionFee: ReverseMoney;
  returnFee: ReverseMoney;
}

export default async function setQuotation(
  payload: SetQuotationInternalArgs | SetQuotationWithSubcontractorArgs,
): Promise<BaseBackResponse> {
  try {
    return await apiCall(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.FILE_ID, payload.fileId),
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          sellerId: payload.sellerId,
          description: payload.description,
          type: payload.type,
          state: payload.state,
          subcontractorQuoteNumber:
            payload.type === QuotationTypes.Subcontractor
              ? payload.subcontractorQuoteNumber
              : undefined,
          spareParts: payload.spareParts,
          unknownSparePartsFromBuyingPrice:
            payload.unknownSparePartsFromBuyingPrice,
          unknownSparePartsFromSellingPrice:
            payload.unknownSparePartsFromSellingPrice,
          services: payload.services,
          discounts: payload.discounts,
          unknownServices: payload.unknownServices,
          administrativeFees: payload.administrativeFees,
          destructionFee:
            payload.type === QuotationTypes.Subcontractor
              ? payload.destructionFee
              : undefined,
          returnFee:
            payload.type === QuotationTypes.Subcontractor
              ? payload.returnFee
              : undefined,
          isBonusRemoved: payload.isBonusRemoved,
        },
      },
    );
  } catch (exception) {
    return { errors: (exception as any).errors, failure: true };
  }
}
