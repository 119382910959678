import { gql, TypedDocumentNode } from '@apollo/client';

export const confirmSellerToProduction: TypedDocumentNode<
  { statesUpdated: string },
  { sellerId: string }
> = gql`
  subscription sellerPushedToProd($sellerId: UUID!) {
    sellerPushedToProd(sellerId: $sellerId) {
      updatedAt
    }
  }
`;
