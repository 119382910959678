import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSearchParams } from '@savgroup-front-common/core/src/hooks';
import { userManager } from '@savgroup-front-common/core/src/services/userManager';

import { logError } from '../../../configuration';
import { signIn } from '../../../domains/login/actions';

import { useAuth } from './AuthProviderContext';
import LoadingScreen from './LoadingScreen';

const useOAuthServer = () => {
  const dispatch = useDispatch();

  const query = useSearchParams();
  const defaultRedirectUrl = query.get('redirectUrl') || undefined;

  const search = new URLSearchParams(window.location.search);
  const state = search.get('state');

  const { pathname, search: urlSearch } = useLocation();

  const navigate = useNavigate();
  const { user, login } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        if (!user && state) {
          await userManager.signinCallback(window.location.href);

          return;
        }

        const urlBeforeRedirection = (user.state as { redirectUrl?: string })
          ?.redirectUrl;

        if (urlBeforeRedirection && !urlBeforeRedirection?.startsWith('/')) {
          throw new Error('Invalid redirect URL');
        }

        dispatch(signIn(() => navigate(urlBeforeRedirection ?? '/')) as any);
      } catch (e: any) {
        logError(e);

        if (e.message === 'No matching state found in storage') {
          login({
            extraQueryParams: {},
            state: {
              redirectUrl: `${pathname}?${urlSearch}`,
            },
          });
        }
      }
    })();
  }, [
    defaultRedirectUrl,
    dispatch,
    login,
    navigate,
    pathname,
    urlSearch,
    state,
    user,
  ]);
};

export const Callback: React.FC = () => {
  useOAuthServer();

  return <LoadingScreen notifications={[]} />;
};
