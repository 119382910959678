import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { SearchFilterFacet } from './filterFilesCommand';

const ENDPOINT = `${APIConfiguration.search}export/filteredFiles`;

export enum EXPORT_FORMAT {
  CSV = 'Csv',
  EXCEL = 'Excel',
}

export interface ExportFilteredFilesCommandArgs {
  query?: string;
  exportFormat: EXPORT_FORMAT;
  filters?: SearchFilterFacet[];
}

async function exportFilteredFilesCommand({
  exportFormat,
  query,
  filters,
}: ExportFilteredFilesCommandArgs): Promise<
  BackResponse<string> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        query,
        filters,
        exportFormat,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default exportFilteredFilesCommand;
