import { APIConfiguration } from '@savgroup-front-common/configuration';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.seller}console/entriesVisibility`;

export async function getConsoleEntriesVisibilityQuery({
  sellerId,
  importTypes,
}: {
  sellerId: string;
  importTypes: string[];
}): Promise<BackResponse<Record<string, boolean>> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<Record<string, boolean>>>(
      buildUrl(ENDPOINT, { sellerId, importTypes }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}
