import React from 'react';
import { useIsFetching } from 'react-query';

import { safeFormattedIntlString } from '../../formatters';
import { Loader } from '../loader';

import { $GlobalLoader } from './GlobalLoader.styles';
import messages from './messages';

type GlobalLoaderProps = {
  isLoading?: boolean;
};

export const GlobalLoader: React.FC<GlobalLoaderProps> = () => {
  const isFetching = useIsFetching();

  if (isFetching === 0) {
    return <></>;
  }

  return (
    <$GlobalLoader title={safeFormattedIntlString(messages.dataLoading)}>
      <Loader size="34px" />
    </$GlobalLoader>
  );
};
