import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}dispatch/send/${COMMON_QUERY_PARAMS.DISPATCH_ID}`;

const sendDispatchCommand = async ({
  dispatchId,
  carrier,
  trackingNumber,
}: {
  dispatchId: string;
  carrier?: string;
  trackingNumber: string;
}): Promise<BackResponse<string> | BackResponseFailure> => {
  try {
    return await apiCall<BackResponse<string>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.DISPATCH_ID, dispatchId),
      {
        method: SUPPORTED_METHODS.POST,
        json: { carrier, trackingNumber },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default sendDispatchCommand;
