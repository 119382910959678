import styled from 'styled-components';

import { Button } from '@savgroup-front-common/core/src/atoms/button';
import { Link } from '@savgroup-front-common/core/src/atoms/link';

export const $SearchResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const $SearchResultContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const $SearchResultValue = styled.div`
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

export const $SearchResultValueItem = styled(Link)`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.newUI?.iconColors.primary.bgColor};
    border-radius: 8px;
    text-decoration: none;
  }
`;

export const $SearchResultItemContent = styled.div<{
  $color?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-wrap: nowrap;

  .hightlighted {
    color: ${({ $color }) => $color};
  }
`;

export const $SearchResultIcon = styled.div<{ $bgColor?: string }>`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: ${({ $bgColor, theme }) =>
    $bgColor || theme.newUI?.iconColors.primary.bgColor};
`;

export const $SearchResultName = styled.div`
  color: ${({ theme }) => theme.newUI?.iconColors.disabled.color};
  font-size: ${({ theme }) => theme.fonts.size.small};
  margin-top: -4px;
`;

export const $SearchResultRef = styled.div`
  max-width: -webkit-fill-available;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const $Button = styled(Button)`
  max-height: 16px;
  min-height: 16px;
  height: 16px;
  padding-top: 0;
  padding-bottom: 0;

  &&:hover,
  &&:focus,
  &&:active {
    text-decoration: underline;
    background-color: transparent;
  }
`;

export const $NoResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const $NoResultsWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const $NoResultsTitle = styled.div`
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  color: ${({ theme }) => theme.newUI?.variationColors.dark1};
  text-align: center;
`;

export const $NoResultsDescription = styled.div`
  color: ${({ theme }) => theme.newUI?.defaultColors.secondaryText};
  text-align: center;
`;
