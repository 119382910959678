import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FilterPreset,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}search/filter`;

export interface UpsertFilterCommandArgs {
  id: string;
  name: string;
  filters?: { name: string; facets?: { name: string; included: boolean }[] }[];
  profiles: string[];
  tenantId: string;
}

async function upsertFilterCommand(
  filterPreset: UpsertFilterCommandArgs,
): Promise<BackResponse<FilterPreset> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<FilterPreset>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        filter: filterPreset,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default upsertFilterCommand;
