import React, { FC } from 'react';

import {
  BUTTON_TYPES,
  NOTIFICATION_TYPES,
} from '@savgroup-front-common/constants';
import { Banner } from '@savgroup-front-common/core/src/atoms/Banners';
import Button from '@savgroup-front-common/core/src/atoms/button/Button';
import { Heading } from '@savgroup-front-common/core/src/atoms/Heading';
import {
  SubscriptionNames,
  useGraphQlSseContext,
} from '@savgroup-front-common/core/src/components/GraphqlSseContext/GraphqlSseContext';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';

import { useAppContext } from '../../../../../../app/App.context';
import { PlanDto } from '../../../ChoosePlan.types';

import { confirmSellerToProduction } from './ConfirmPushToProductionModalContent.gql';
import { useConfirmPushToProductionModalContent } from './ConfirmPushToProductionModalContent.hooks';
import { $ActionRow } from './ConfirmPushToProductionModalContent.styles';
import messages from './messages';

interface ConfirmPushToProductionModalContentProps {
  onClose: () => void;
  plan: PlanDto;
}

export const ConfirmPushToProductionModalContent: FC<
  ConfirmPushToProductionModalContentProps
> = ({ onClose, plan }) => {
  const { handlePushToProduction, isLoading, isSuccess } =
    useConfirmPushToProductionModalContent({ plan });
  const { selectedSeller } = useAppContext();
  const sellerId = selectedSeller?.id;

  const { onSubscribe, onUnsubscribe } = useGraphQlSseContext();

  onSubscribe({
    subscriptionKey: `${SubscriptionNames.sellerPushedToProd}_${sellerId}`,
    query: confirmSellerToProduction,
    variables: { sellerId },
    onNext: () => {
      onClose();
      window.location.reload();
    },
  });

  React.useEffect(() => {
    return () => {
      onUnsubscribe(
        `${SubscriptionNames.sellerPricingPlanUpdated}_${sellerId}`,
      );
    };
  }, [onUnsubscribe, sellerId]);

  return (
    <>
      <Heading level={3}>
        <SafeFormattedMessageWithoutSpread
          message={messages.youWillExitDemoContext}
        />
      </Heading>

      <Banner notificationType={NOTIFICATION_TYPES.INFO} isFluid>
        <SafeFormattedMessageWithoutSpread
          message={messages.youWillExitDemoContextDescription}
        />
      </Banner>

      <$ActionRow>
        <Button
          type={BUTTON_TYPES.BUTTON}
          onClick={onClose}
          isLoading={isLoading}
          danger
          naked
          disabled={isSuccess}
        >
          <SafeFormattedMessageWithoutSpread message={messages.stayInDemo} />
        </Button>

        <Button
          primary
          type={BUTTON_TYPES.BUTTON}
          onClick={() => {
            handlePushToProduction();
          }}
          isLoading={isLoading}
          disabled={isSuccess}
        >
          <SafeFormattedMessageWithoutSpread message={messages.confirm} />
        </Button>
      </$ActionRow>
    </>
  );
};
