import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  CURRENCIES,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.claim}claims/${COMMON_QUERY_PARAMS.CLAIM_ID}/solutions`;

export interface SolutionSummary {
  solutionTypeId: string;
  solutionGroupId: string;
  name: string;
  uniqueName: string;
  price: number;
  priceWithTax: number;
  priceCurrencyCode: CURRENCIES;
  didactic: string;
  key: string;
  potentialActorsId: string[];
  iconKey?: string;
  relatedServiceUniqueName?: string;
}

export interface SolutionStepInfo {
  selectedWarrantyId: string;
  selectedWarrantyName: string;
  solutions: SolutionSummary[];
}

async function getClaimSolutions({
  claimId,
}: {
  claimId: string;
}): Promise<BackResponse<SolutionStepInfo> | BackResponseFailure> {
  try {
    return await apiCall<BackResponse<SolutionStepInfo>>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CLAIM_ID, claimId),
      { method: SUPPORTED_METHODS.GET },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
}

export default getClaimSolutions;
