import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/importversions`;

export interface ImportVersionWithUserDetailsDto {
  id: string;
  creationDateUtc: string;
  comment?: string;
  sellerId: string;
  files: {
    fileName?: string;
    deactivatedRuleSetId?: string;
    activatedRuleSetId: string;
    importType: string;
  }[];
  userId?: string;
  userFirstName?: string;
  userLastName?: string;
}

interface GetVersionsQueryArgs {
  sellerId: string;
  page?: number;
  pageSize?: number;
}

const getVersionsQuery = async (
  payload: GetVersionsQueryArgs,
): Promise<
  BackResponseFailure | BackResponse<ImportVersionWithUserDetailsDto[]>
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      payload.sellerId,
    );

    return await apiCall<BackResponse<ImportVersionWithUserDetailsDto[]>>(
      buildUrl(url, {
        page: payload.page,
        pageSize: payload.pageSize,
      }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getVersionsQuery;
