import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}parts/autocomplete`;

async function getAutoCompleteReferencesCommand({
  query,
  sellerId,
  fileId,
}: {
  query?: string;
  sellerId: string;
  fileId?: string;
}): Promise<BackResponse<string[]> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<string[]>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        query,
        sellerId,
        fileId,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default getAutoCompleteReferencesCommand;
