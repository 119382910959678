import styled from 'styled-components';

import { SwipeableDrawer } from '@savgroup-front-common/core/src/atoms/SwipeableDrawer';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $Header = styled.header`
  display: flex;
`;

interface BlockProps {
  $cornerColor?: string;
}

export const $MenuHamburger = styled.button<BlockProps>`
  outline: 0;
  border: none;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  transition-property: background-color;
  height: ${rem(48)};

  background-color: ${({ theme }) => theme.newUI.variationColors.light1};
  padding: 0 1rem;

  &::after {
    content: '';
    width: 30px;
    height: 30px;
    background-color: ${({ $cornerColor }) => $cornerColor};
    position: absolute;
    top: -15px;
    left: -15px;
    transform: rotate(45deg);
  }
`;

export const $RightHeaderContainer = styled.div`
  display: flex;
`;

export const $SwipeableDrawer = styled(SwipeableDrawer)`
  background: ${({ theme }) => theme.newUI.variationColors.light1};
`;
