import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FilterScope,
} from '@savgroup-front-common/types';
import { Facet } from '@savgroup-front-common/types/src/SearchResultList';

const ENDPOINT = `${APIConfiguration.sparePart}search/filter/indexes`;

export interface FacetCategory {
  name: string;
  facets: Facet[];
}

const getFilterFullIndexesQuery = async ({
  scope = FilterScope.SPARE_PART_REQUESTS,
}: {
  scope?: FilterScope;
}): Promise<BackResponse<FacetCategory[]> | BackResponseFailure> => {
  try {
    const response = await apiCall<BackResponse<FacetCategory[]>>(
      buildUrl(ENDPOINT, { scope }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getFilterFullIndexesQuery;
