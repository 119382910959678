import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  DeliveryLocationType,
  ReverseMoneyNumberAmount,
  SparePartDeliveryLocationDto,
  SparePartRequestDeliveryAddress,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requests/handling/deliveryLocations`;

export interface GetValidDeliveryLocationsCommandArgs {
  sellerId: string;
  id?: string;
  fileId?: string;
  userId?: string;
  deliveryAddressType?: string | SparePartRequestDeliveryAddress;
  deliveryCompanyName?: string;
  deliveryFirstName?: string;
  deliveryLastName?: string;
  deliveryAddress?: string;
  deliveryAdditionalAddress?: string;
  deliveryPostalCode?: string;
  deliveryCountry?: string;
  deliveryCity?: string;
  deliveryPhone?: string;
  requesterFirstName?: string;
  requesterLastName?: string;
  requestLines?: {
    id: string;
    quantity: number;
    excludingTaxPurchasePriceEur: ReverseMoneyNumberAmount;
    recommendedSalePriceIncludingTaxes: ReverseMoneyNumberAmount;
    minDeliveryDays: number;
    maxDeliveryDays: number;
    sparePartSupplierId: string;
    externalRequestLineId?: string;
    externalOrderId?: string;
    sparePartId: string;
    sparePartSupplierAssociationId: string;
    manufacturerReference: string;
    subCategory: string;
    availableQuantity?: number;
  }[];
  longitude?: number;
  latitude?: number;
  maxDistance?: number;
}

export type SparePartDeliveryLocations = SparePartDeliveryLocationDto[];

export interface GetValidDeliveryLocationsCommandResponse {
  stores?: SparePartDeliveryLocationDto[];
  client?: SparePartDeliveryLocationDto;
  customAddress?: SparePartDeliveryLocationDto;
  depositStores?: SparePartDeliveryLocationDto[];
  workshops?: SparePartDeliveryLocationDto[];
  warehouses?: SparePartDeliveryLocationDto[];
  boxes?: SparePartDeliveryLocationDto[];
  hubs?: SparePartDeliveryLocationDto[];
  onPremise?: SparePartDeliveryLocationDto;
  van?: {
    deliveryLocationType: DeliveryLocationType.VAN;
  };
}

export async function getValidDeliveryLocationsCommand({
  sellerId,
  id,
  fileId,
  userId,
  deliveryAddressType,
  deliveryAddress,
  requestLines,
  longitude,
  latitude,
  maxDistance,
}: GetValidDeliveryLocationsCommandArgs): Promise<
  BackResponse<GetValidDeliveryLocationsCommandResponse> | BackResponseFailure
> {
  try {
    return await apiCall<
      BackResponse<GetValidDeliveryLocationsCommandResponse>
    >(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
        sparePartRequestDto: {
          id,
          fileId,
          deliveryAddressType,
          deliveryAddress,
          userId,
          requestLines: requestLines?.map((line) => {
            return {
              id: line.id,
              sparePartSupplierId: line.sparePartSupplierId,
              sparePartId: line.sparePartId,
              quantity: line.quantity,
            };
          }),
        },
        coordinates: {
          longitude,
          latitude,
        },
        maxDistance,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
