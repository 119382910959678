import React from 'react';

import { CREATE_MODAL_TYPES } from '@savgroup-front-common/constants/src/control';
import {
  MenuItem,
  VERTICAL_MENU_ITEM_TYPES,
} from '@savgroup-front-common/core/src/atoms/Menu';
import {
  DevicesIcon,
  HowToRegIcon,
  OrderIcon,
  ReceiptIcon,
  SparePartIcon,
  StockIcon,
  UserGroupIcon,
  UserIcon,
} from '@savgroup-front-common/core/src/protons/icons';
import { MessageType } from '@savgroup-front-common/types';
import { SPARE_PART_ROUTES } from 'control/constants/routing';

import { TITLE_MESSAGES_BY_CREATE_MODAL_TYPE } from './CreateButton.constants';
import messages from './messages';

const getTitleMessageByOption = (option: CREATE_MODAL_TYPES) => {
  const result = TITLE_MESSAGES_BY_CREATE_MODAL_TYPE[option];

  if (!result) {
    throw new Error(`The option: ${option} is not handled on getTitleMessage.`);
  }

  return result;
};

export const getAuthorizedOptions = (
  authorizations: CREATE_MODAL_TYPES[],
  onClick: (val: MenuItem) => void,
): MenuItem[] => {
  const { authorizedOptions } = authorizations.reduce<{
    previousTitle: MessageType | null;
    authorizedOptions: MenuItem[];
  }>(
    (acc, authorization, index) => {
      const title = getTitleMessageByOption(authorization);
      const addedValues = [...acc.authorizedOptions];

      if (title && acc.previousTitle !== title) {
        addedValues.push({
          type: VERTICAL_MENU_ITEM_TYPES.TITLE,
          label: title,
          key: `${title.id}_${index}`,
        });
      }

      switch (authorization) {
        case CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.FILE_ORDER_REFERENCE,
            label: messages.orderReference,
            icon: <OrderIcon />,
            onClick,
          });
          break;
        case CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.FILE_PRODUCT_REFERENCE,
            label: messages.productReference,
            icon: <DevicesIcon />,
            onClick,
          });
          break;
        case CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.FILE_BY_CUSTOMER_INFORMATION,
            label: messages.customerInformation,
            icon: <UserIcon />,
            onClick,
          });
          break;
        case CREATE_MODAL_TYPES.FILE_OWNER_STOCK:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.FILE_OWNER_STOCK,
            label: messages.fileOwnerStock,
            icon: <StockIcon />,
            onClick,
          });
          break;
        case CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.FILE_RECEIPT_INFORMATION,
            label: messages.receiptInformation,
            icon: <ReceiptIcon />,
            onClick,
          });
          break;
        case CREATE_MODAL_TYPES.SPARE_PART_REQUEST:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.LINK,
            key: CREATE_MODAL_TYPES.SPARE_PART_REQUEST,
            label: messages.sparePartRequest,
            icon: <SparePartIcon />,
            href: SPARE_PART_ROUTES.CREATE_REQUEST,
          });
          break;

        case CREATE_MODAL_TYPES.SELLER:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.SELLER,
            label: messages.seller,
            icon: <HowToRegIcon />,
            onClick,
          });
          break;

        case CREATE_MODAL_TYPES.TENANT:
          addedValues.push({
            type: VERTICAL_MENU_ITEM_TYPES.BUTTON,
            key: CREATE_MODAL_TYPES.TENANT,
            label: messages.tenant,
            icon: <UserGroupIcon />,
            onClick,
          });
          break;

        default:
          throw new Error(
            `The authorization: ${authorization} is not handled.`,
          );
      }

      return {
        ...acc,
        previousTitle: title,
        authorizedOptions: addedValues,
      };
    },
    {
      previousTitle: null,
      authorizedOptions: [],
    },
  );

  return authorizedOptions;
};
