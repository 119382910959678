import { SigninRedirectArgs, SignoutRedirectArgs, User } from 'oidc-client-ts';
import React, { createContext, useContext, useEffect, useState } from 'react';

import {
  getUser,
  login,
  logout,
  userManager,
} from '@savgroup-front-common/core/src/services/userManager';

interface AuthContextProps {
  isAuthenticated: boolean;
  user: User;
  login: (args?: SigninRedirectArgs) => Promise<void>;
  logout: (args?: SignoutRedirectArgs) => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    getUser().then((currentUser) => {
      setUser(currentUser);
    });

    const handleUserLoaded = (user: User) => {
      setUser(user);
    };

    const handleUserSignedOut = () => {
      setUser(null);
    };

    const handleAccessTokenExpired = () => {
      setUser(null);
    };

    userManager.events.addUserLoaded(handleUserLoaded);
    userManager.events.addUserSignedOut(handleUserSignedOut);
    userManager.events.addAccessTokenExpired(handleAccessTokenExpired);

    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      userManager.events.removeUserSignedOut(handleUserSignedOut);
      userManager.events.removeAccessTokenExpired(handleAccessTokenExpired);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: user != null,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
