import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponseFailure,
  BaseBackResponse,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.import}console/${COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE}/${COMMON_QUERY_PARAMS.ID}`;

interface DeleteRuleCommandPayload {
  ruleId: string;
  ruleType: string;
}

const deleteRuleCommand = async ({
  ruleId,
  ruleType,
}: DeleteRuleCommandPayload): Promise<
  BaseBackResponse | BackResponseFailure
> => {
  try {
    return await apiCall<BaseBackResponse>(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.CONSOLE_RULE_TYPE, ruleType).replace(
        COMMON_QUERY_PARAMS.ID,
        ruleId,
      ),
      {
        method: SUPPORTED_METHODS.DELETE,
      },
    );
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default deleteRuleCommand;
