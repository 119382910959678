import filter from 'lodash/filter';
import get from 'lodash/get';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import md5 from 'md5';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import 'regenerator-runtime';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logCritical } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import {
  arrayBufferFromBlob,
  buildUrl,
  getFileType,
  throttledTakeLatest,
} from '@savgroup-front-common/core/src/helpers';
import {
  callAndGetResponse,
  reduxExtendedRequestSaga,
} from '@savgroup-front-common/core/src/services';

import { loadBackOfficeLink } from '../access/actionCreators';

import { loadMarketplaceDetailedTicket } from './actionCreators';
import {
  CLOSE_MARKETPLACE_TICKET,
  CREATE_OWNER_FOR_ORDER,
  GENERATE_OWNER_AND_BACKOFFICE_LINK,
  LOAD_CLAIM_INFO_BY_ORDER,
  LOAD_CLAIMS_BY_ORDER,
  LOAD_FILES_BY_ORDER,
  LOAD_FILTERS,
  LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER,
  LOAD_MARKETPLACE_DETAILED_TICKET,
  LOAD_MARKETPLACE_TICKETS,
  LOAD_MARKETPLACE_TICKETS_BY_ORDER,
  LOAD_OWNER_PRODUCTS,
  LOAD_SELLER_PRODUCTS_INFO,
  REFRESH_TICKET_DETAILS,
  SEND_MESSAGE,
  SNOOZE_TICKET,
  TRACK_USER_ACTIVITY_ON_TICKET,
  UPLOAD_MARKETPLACE_ATTACHMENTS,
} from './actionTypes';

function encodeMessage(message) {
  return (
    message &&
    [...message]
      .map((c) => {
        const codePoint = c.codePointAt(0);

        return codePoint > 255 ? `&#${codePoint};` : c;
      })
      .join('')
  );
}

function* loadFiltersWorker() {
  yield call(
    callAndGetResponse,
    LOAD_FILTERS,
    `${APIConfiguration.marketplace}tickets/filters`,
  );
  yield put(LOAD_FILTERS.end());
}

function* loadMarketplaceTicketsWorker({ payload }) {
  const { filters, searchOrder, searchQuery } = payload || {};
  const formattedFilters = {
    ...mapValues(filters, (toggles) =>
      filter(map(toggles, (isToggled, name) => (isToggled ? name : undefined))),
    ),
    ...searchOrder,
  };

  if (searchQuery) {
    formattedFilters.orderReference = searchQuery;
  }

  yield call(
    callAndGetResponse,
    LOAD_MARKETPLACE_TICKETS,
    buildUrl(`${APIConfiguration.marketplace}tickets`, formattedFilters, {
      encode: false,
      arrayFormat: 'brackets',
    }),
  );
  yield put(LOAD_MARKETPLACE_TICKETS.end());
}

function* refreshTicketDetailsWorker({ payload: ticketId }) {
  yield call(
    callAndGetResponse,
    REFRESH_TICKET_DETAILS,
    `${APIConfiguration.marketplace}tickets/${ticketId}/refresh`,
    {
      method: SUPPORTED_METHODS.PUT,
    },
    {
      ticketId,
    },
  );
  yield put(REFRESH_TICKET_DETAILS.end());
}

function* loadMarketplaceDetailedTicketWorker({ payload: ticketId }) {
  yield call(
    callAndGetResponse,
    LOAD_MARKETPLACE_DETAILED_TICKET,
    `${APIConfiguration.marketplace}tickets/${ticketId}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      ticketId,
    },
  );
  yield put(
    LOAD_MARKETPLACE_DETAILED_TICKET.end({
      ticketId,
    }),
  );
}

function* closeMarketplaceTicketWorker({ payload }) {
  const { ticketId, refreshList } = payload;

  yield call(
    callAndGetResponse,
    CLOSE_MARKETPLACE_TICKET,
    `${APIConfiguration.marketplace}tickets/${ticketId}/close`,
    {
      method: SUPPORTED_METHODS.PUT,
    },
    {
      ticketId,
    },
  );
  yield put(CLOSE_MARKETPLACE_TICKET.end());
  if (refreshList) {
    refreshList();
  }
}

function* loadClaimInfoByOrderWorker({ payload: { orderId } }) {
  yield put(LOAD_CLAIM_INFO_BY_ORDER.start({ orderId, indexer: orderId }));
  const claimsResponseAction = yield call(
    callAndGetResponse,
    LOAD_CLAIMS_BY_ORDER,
    `${APIConfiguration.claim}orders/${orderId}/claims`,
    { indexer: orderId },
  );

  const filesResponseAction = yield call(
    callAndGetResponse,
    LOAD_FILES_BY_ORDER,
    `${APIConfiguration.workflow}files/${orderId}`,
    { indexer: orderId },
  );

  if (
    claimsResponseAction.type === LOAD_CLAIMS_BY_ORDER.SUCCEEDED &&
    (filesResponseAction === null ||
      get(filesResponseAction, 'type') === LOAD_FILES_BY_ORDER.SUCCEEDED)
  ) {
    yield put(
      LOAD_CLAIM_INFO_BY_ORDER.success(
        {
          claimsInfo: get(claimsResponseAction.payload, 'value'),
          filesInfo: get(filesResponseAction, ['payload', 'value']),
        },
        { orderId, indexer: orderId },
      ),
    );
  } else if (
    claimsResponseAction.type === LOAD_CLAIMS_BY_ORDER.CANCELLED ||
    get(filesResponseAction, 'type') === LOAD_FILES_BY_ORDER.CANCELLED
  ) {
    yield put(LOAD_CLAIM_INFO_BY_ORDER.cancel({ orderId, indexer: orderId }));
  } else {
    yield put(
      LOAD_CLAIM_INFO_BY_ORDER.error(null, { orderId, indexer: orderId }),
    );
  }
  yield put(LOAD_CLAIM_INFO_BY_ORDER.end({ indexer: orderId }));
}
function* loadClaimInfoByOrderWatcher() {
  yield takeEvery(LOAD_CLAIM_INFO_BY_ORDER.BASE, loadClaimInfoByOrderWorker);
}

function* sendMessageWorker({ payload }) {
  const { ticketId, textBody, to, attachments, callback, refreshList } =
    payload || {};
  const responseAction = yield call(
    callAndGetResponse,
    SEND_MESSAGE,
    `${APIConfiguration.marketplace}interactivemessages`,
    {
      method: SUPPORTED_METHODS.POST,
      json: {
        ticketId,
        to,
        textBody: encodeMessage(textBody),
        attachments,
      },
    },
    {
      ticketId,
    },
  );

  if (callback) {
    callback(responseAction.type !== SEND_MESSAGE.SUCCEEDED);
  }
  yield put(SEND_MESSAGE.end());
  yield put(loadMarketplaceDetailedTicket(ticketId));
  if (refreshList) {
    refreshList();
  }
}

function* createOwnerForOrderWorker({ payload: orderId }) {
  const meta = {
    orderId,
  };

  yield call(
    callAndGetResponse,
    CREATE_OWNER_FOR_ORDER,
    `${APIConfiguration.owner}owners/forOrder/${orderId}`,
    {
      method: SUPPORTED_METHODS.POST,
    },
    meta,
  );
  yield put(CREATE_OWNER_FOR_ORDER.end(meta));
}

function* loadMarketplaceTicketsByOrderWorker({ payload: orderId }) {
  yield call(
    callAndGetResponse,
    LOAD_MARKETPLACE_TICKETS_BY_ORDER,
    `${APIConfiguration.marketplace}orders/${orderId}/tickets`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      orderId,
    },
  );
  yield put(LOAD_MARKETPLACE_TICKETS_BY_ORDER.end());
}

const ApiConnector = {
  BACKMARKET: 'BackMarket',
  CDISCOUNT: 'CDiscount',
  PRICE_MINISTER: 'PriceMinister',
  FNAC: 'Fnac',
  AMZON: 'Amazon',
};

function* loadMarketplaceCapabilitiesBySellerWorker({
  payload: { marketplace, sellerId },
}) {
  if (!sellerId) {
    yield put(LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.end());

    return;
  }

  if (!Object.values(ApiConnector).includes(marketplace)) {
    return;
  }

  yield call(
    callAndGetResponse,
    LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER,
    `${APIConfiguration.marketplace}capabilities/${sellerId}/${marketplace}`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    {
      marketplace,
      sellerId,
    },
  );
  yield put(LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.end());
}

function* snoozeTicketWorker({ payload }) {
  const { id, date, refreshList } = payload;
  const meta = { id };

  yield call(
    callAndGetResponse,
    SNOOZE_TICKET,
    `${APIConfiguration.marketplace}tickets/${id}/snooze`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        snoozeUntilDateUtc: date,
      },
    },
    meta,
  );
  yield put(SNOOZE_TICKET.end(meta));
  if (refreshList) {
    refreshList();
  }
}

function* uploadMarketplaceAttachmentsWorker({ payload: { ticketId, file } }) {
  const language = yield select(selectLocale);
  const fileArrayBuffer = yield call(arrayBufferFromBlob, file);
  const hash = md5(fileArrayBuffer);
  const fileType = getFileType(new Uint8Array(fileArrayBuffer));

  if (fileType && fileType.mime !== file.type) {
    // eslint-disable-next-line no-console
    console.warn(
      // eslint-disable-next-line max-len
      `[uploadMarketplaceAttachmentsWorker] file '${file.name}' uploaded had an incorrect mime type. Before: ${file.type}, After: ${fileType.mime}`,
    );
  }

  const fileToUpload = fileType
    ? new File(
        [fileArrayBuffer],
        fileType.ext
          ? file.name.replace(/(\.[^/.]*)?$/, `.${fileType.ext}`)
          : file.name,
        {
          type: fileType.mime || file.type,
        },
      )
    : file;

  const body = new FormData();

  body.append('file', fileToUpload);
  const meta = {
    ticketId,
    hash,
    fileName: fileToUpload.name,
    mimeType: fileToUpload.type,
    fileSize: fileToUpload.size,
  };

  yield call(
    reduxExtendedRequestSaga,
    UPLOAD_MARKETPLACE_ATTACHMENTS,
    `${APIConfiguration.marketplace}tickets/${ticketId}/attachments`,
    {
      method: SUPPORTED_METHODS.POST,
      body,
      language,
    },
    meta,
  );
  yield put(UPLOAD_MARKETPLACE_ATTACHMENTS.end(meta));
}

function* trackUserActivityOnTicketWorker({ payload }) {
  const { ticketId, userId } = payload;
  const meta = {
    ticketId,
    userId,
  };

  yield call(
    callAndGetResponse,
    TRACK_USER_ACTIVITY_ON_TICKET,
    `${APIConfiguration.marketplace}tickets/${ticketId}`,
    {
      method: SUPPORTED_METHODS.PUT,
      json: {
        userId,
      },
    },
    meta,
  );

  yield put(TRACK_USER_ACTIVITY_ON_TICKET.end(meta));
}

function* generateOwnerAndBackofficeLinkWorker({ payload }) {
  const payloadCopy = { ...payload };
  const { ownerId, orderId } = payload;

  if (!ownerId) {
    const meta = { orderId, indexer: orderId };
    const response = yield call(
      callAndGetResponse,
      CREATE_OWNER_FOR_ORDER,
      `${APIConfiguration.owner}owners/forOrder/${orderId}`,
      { method: SUPPORTED_METHODS.POST },
      meta,
    );

    payloadCopy.ownerId = response.payload.value;
    yield put(CREATE_OWNER_FOR_ORDER.end(meta));
  }
  yield put(loadBackOfficeLink(payloadCopy));
}

function* generateOwnerAndBackofficeLinkWatcher() {
  yield takeEvery(
    GENERATE_OWNER_AND_BACKOFFICE_LINK.BASE,
    generateOwnerAndBackofficeLinkWorker,
  );
}

function* loadFiltersWatcher() {
  yield takeEvery(LOAD_FILTERS.BASE, loadFiltersWorker);
}

function* loadMarketplaceTicketsWatcher() {
  yield throttledTakeLatest(
    LOAD_MARKETPLACE_TICKETS.BASE,
    loadMarketplaceTicketsWorker,
  );
}

function* closeMarketplaceTicketWatcher() {
  yield takeEvery(CLOSE_MARKETPLACE_TICKET.BASE, closeMarketplaceTicketWorker);
}

function* refreshTicketDetailsWatcher() {
  yield throttledTakeLatest(
    REFRESH_TICKET_DETAILS.BASE,
    refreshTicketDetailsWorker,
  );
}

function* loadMarketplaceDetailedTicketWatcher() {
  yield takeEvery(
    LOAD_MARKETPLACE_DETAILED_TICKET.BASE,
    loadMarketplaceDetailedTicketWorker,
  );
}

function* sendMessageWatcher() {
  yield takeEvery(SEND_MESSAGE.BASE, sendMessageWorker);
}

function* createOwnerForOrderWatcher() {
  yield takeEvery(CREATE_OWNER_FOR_ORDER.BASE, createOwnerForOrderWorker);
}

function* loadMarketplaceTicketsByOrderWatcher() {
  yield takeEvery(
    LOAD_MARKETPLACE_TICKETS_BY_ORDER.BASE,
    loadMarketplaceTicketsByOrderWorker,
  );
}

function* loadMarketplaceCapabilitiesBySellerWatcher() {
  yield takeEvery(
    LOAD_MARKETPLACE_CAPABILITIES_BY_SELLER.BASE,
    loadMarketplaceCapabilitiesBySellerWorker,
  );
}

function* snoozeTicketWatcher() {
  yield takeEvery(SNOOZE_TICKET.BASE, snoozeTicketWorker);
}

function* uploadMarketplaceAttachmentsWatcher() {
  yield takeEvery(
    UPLOAD_MARKETPLACE_ATTACHMENTS.BASE,
    uploadMarketplaceAttachmentsWorker,
  );
}

function* trackUserActivityOnTicketWatcher() {
  yield takeEvery(
    TRACK_USER_ACTIVITY_ON_TICKET.BASE,
    trackUserActivityOnTicketWorker,
  );
}

function* loadOwnerProductsWorker({ payload }) {
  yield call(
    callAndGetResponse,
    LOAD_OWNER_PRODUCTS,
    `${APIConfiguration.owner}owners/${payload}/products`,
    {
      method: SUPPORTED_METHODS.GET,
    },
    payload,
  );
  yield put(LOAD_OWNER_PRODUCTS.end());
}

function* loadOwnerProductsWatcher() {
  yield takeEvery(LOAD_OWNER_PRODUCTS.BASE, loadOwnerProductsWorker);
}

function* loadSellerProductsInfoWorker({ payload }) {
  yield call(
    callAndGetResponse,
    LOAD_SELLER_PRODUCTS_INFO,
    `${APIConfiguration.config}sellers/products/list`,
    {
      method: SUPPORTED_METHODS.POST,
      json: payload,
    },
  );
  yield put(LOAD_SELLER_PRODUCTS_INFO.end());
}

function* loadSellerProductsInfoWatcher() {
  yield takeEvery(LOAD_SELLER_PRODUCTS_INFO.BASE, loadSellerProductsInfoWorker);
}

export default function* mainSaga() {
  try {
    yield all([
      loadFiltersWatcher(),
      loadMarketplaceTicketsWatcher(),
      loadMarketplaceDetailedTicketWatcher(),
      sendMessageWatcher(),
      closeMarketplaceTicketWatcher(),
      loadClaimInfoByOrderWatcher(),
      createOwnerForOrderWatcher(),
      loadMarketplaceTicketsByOrderWatcher(),
      loadMarketplaceCapabilitiesBySellerWatcher(),
      snoozeTicketWatcher(),
      uploadMarketplaceAttachmentsWatcher(),
      trackUserActivityOnTicketWatcher(),
      refreshTicketDetailsWatcher(),
      generateOwnerAndBackofficeLinkWatcher(),
      loadOwnerProductsWatcher(),
      loadSellerProductsInfoWatcher(),
    ]);
  } catch (error) {
    logCritical(error);
  }
}
