import { defineMessages } from 'react-intl';

export default defineMessages({
  showMore: {
    id: 'view.filesPage.searchResult.showMore',
    defaultMessage: '+ show more ({value})',
  },
  noResultsTitle: {
    id: 'view.filesPage.searchResult.noResultsTitle',
    defaultMessage: 'No results for your search',
  },
  noResultsDescription: {
    id: 'view.filesPage.searchResult.noResultsDescription',
    defaultMessage: 'Try a different search term',
  },
});
