import { CommonSparePartsService } from '@savgroup-front-common/core/src/api/SpareParts';

import addStockItemGroupInInventoryHandlerCommand from './addStockItemGroupInInventoryHandlerCommand';
import bulkOrderSparePartsCommand from './bulkOrderSparePartsCommand';
import closeInventoryCommand from './closeInventoryCommand';
import { createSparePartRequestNewModelCommand } from './createSparePartRequestNewModelCommand';
import createStockInventoryCommand from './createStockInventoryCommand';
import currentUserFiltersQuery from './currentUserFiltersQuery';
import deleteFilterCommand from './deleteFilterCommand';
import deleteStockInInventoryCommand from './deleteStockInInventoryCommand';
import deleteStockInventoryCommand from './deleteStockInventoryCommand';
import deleteStockItemGroupInInventoryCommand from './deleteStockItemGroupInInventoryCommand';
import downloadBalancingStockItemToRegulateExportQuery from './downloadBalancingStockItemToRegulateExportQuery';
import downloadFiscalInventoryExportQuery from './downloadFiscalInventoryExportQuery';
import generateAndDownloadStockInInventorySheetQuery from './generateAndDownloadStockInInventorySheetQuery';
import getAutoCompleteReferencesCommand from './getAutoCompleteReferencesCommand';
import getAvailableStockForInventoryBySellerIdQuery from './getAvailableStockForInventoryBySellerIdQuery';
import getDeliveryLocationAddressesQuery from './getDeliveryLocationAddressesQuery';
import getDispatchActions from './getDispatchActions';
import getFilterFullIndexesQuery from './getFilterFullIndexesQuery';
import getRequestLineBulkActionsQuery from './getRequestLineBulkActionsQuery';
import getRequestLineSummaryQuery from './getRequestLineSummaryQuery';
import getSparePartRequestLines from './getSparePartRequestLines';
import getSparePartStocks from './getSparePartStocks';
import { getSparePartSuppliersQuery } from './getSparePartSuppliersQuery';
import getSparePartVATPrice from './getSparePartVATPrice';
import getStockInventory from './getStockInventory';
import getStockInventoryBySellerId from './getStockInventoryBySellerId';
import getStockItemGroupInInventoryByStockInInventoryQuery from './getStockItemGroupInInventoryByStockInInventoryQuery';
import getStockItems from './getStockItems';
import getStockItemsByFileId from './getStockItemsByFileId';
import { getValidDeliveryLocationsCommand } from './getValidDeliveryLocationsCommand';
import { getValidSparePartSuppliersQuery } from './getValidSparePartSuppliersQuery';
import { requestReferencingAttachements } from './requestReferencingAttachements';
import { retrieveSpareParts } from './retrieveSpareParts';
import searchDispatchesCommand from './searchDispatchesCommand';
import { searchFullSparePartsQuery } from './searchFullSparePartsQuery';
import { searchRequestsToValidateCommand } from './searchRequestsToValidateCommand';
import { searchSparePartsQuery } from './searchSparePartsQuery';
import sendDispatchCommand from './sendDispatchCommand';
import setSparePartRequest from './setSparePartRequest';
import { setSparePartRequestNewReference } from './setSparePartRequestNewReference';
import setStockToSparePartRequestLine from './setStockToSparePartRequestLine';
import getAuthorizedStocksByUserIdQuery from './stocks/getAuthorizedStocksByUserIdQuery';
import getSparePartStocksBySellerQuery from './stocks/getSparePartStocksBySellerQuery';
import getStocksBySitesCommand from './stocks/getStocksBySitesCommand';
import updateMassStockItemCommand from './updateMassStockItemCommand';
import { updateMultipleSparePartsCommand } from './updateMultipleSparePartsCommand';
import { updateRequestLineNewModelCommand } from './updateRequestLineNewModelCommand';
import { updateRequestLineStatusCommand } from './updateRequestLineStatusCommand';
import updateSparePartRequestLine from './updateSparePartRequestLine';
import updateStockInInventoryInventoriedByCommand from './updateStockInInventoryInventoriedByCommand';
import updateStockInInventoryStateCommand from './updateStockInInventoryStateCommand';
import updateStockItem from './updateStockItem';
import updateStockItemGroupInInventoryCommand from './updateStockItemGroupInInventoryCommand';
import upsertFilterCommand from './upsertFilterCommand';
import { upsertSparePart } from './upsertSparePart';

export const SparePartService = {
  retrieveSpareParts,
  upsertSparePart,
  updateMultipleSparePartsCommand,
  searchSparePartsQuery,
  searchFullSparePartsQuery,
  createSparePartRequestNewModelCommand,
  getValidDeliveryLocationsCommand,
  getValidSparePartSuppliersQuery,
  requestReferencingAttachements,

  getStockInventoryBySellerId,
  getAvailableStockForInventoryBySellerIdQuery,
  getStockInventory,
  createStockInventoryCommand,
  deleteStockInInventoryCommand,
  deleteStockInventoryCommand,
  deleteStockItemGroupInInventoryCommand,
  getStockItemGroupInInventoryByStockInInventoryQuery,
  updateStockItemGroupInInventoryCommand,
  addStockItemGroupInInventoryHandlerCommand,
  updateStockInInventoryStateCommand,
  closeInventoryCommand,
  updateStockInInventoryInventoriedByCommand,
  generateAndDownloadStockInInventorySheetQuery,
  downloadBalancingStockItemToRegulateExportQuery,
  downloadFiscalInventoryExportQuery,

  getSparePartRequestLines,
  getSparePartStocks,
  getSparePartVATPrice,
  getStockItems,
  getStockItemsByFileId,
  setSparePartRequest,
  setStockToSparePartRequestLine,
  updateSparePartRequestLine,
  updateStockItem,
  updateMassStockItemCommand,
  searchRequestsToValidateCommand,
  getDeliveryLocationAddressesQuery,
  updateRequestLineStatusCommand,
  getRequestLineSummaryQuery,
  updateRequestLineNewModelCommand,
  getSparePartSuppliersQuery,

  getAuthorizedStocksByUserIdQuery,
  getSparePartStocksBySellerQuery,
  getStocksBySitesCommand,
  getSellingPriceForSellerQuery:
    CommonSparePartsService.getSellingPriceForSellerQuery,
  setSparePartRequestNewReference,
  getFilterFullIndexesQuery,
  currentUserFiltersQuery,
  upsertFilterCommand,
  deleteFilterCommand,
  searchDispatchesCommand,
  getRequestLineBulkActionsQuery,
  bulkOrderSparePartsCommand,
  getAutoCompleteReferencesCommand,
  sendDispatchCommand,
  getDispatchActions,
};
