import { CommonCarrierService } from '@savgroup-front-common/core/src/api';

import getFileLabelAddress from './getFileLabelAddress';
import getFileLabels from './getFileLabels';
import getFileShippingsQuery from './getFileShippingsQuery';
import getGenericCarrierAccountsDefinitionsQuery from './getGenericCarrierAccountsDefinitionsQuery';
import getGenericCarrierAccountsForUpsQuery from './getGenericCarrierAccountsForUpsQuery';
import listExternalCarrierCompanysQuery from './listExternalCarrierCompanysQuery';
import setFileLabelAddress from './setFileLabelAddress';
import upsertGenericCarrierAccountForUpsCommand from './upsertGenericCarrierAccountForUpsCommand';
import verifyAddress from './verifyAddress';

export const CarrierService = {
  getPickupPoints: CommonCarrierService.getPickupPointByGeoCoordQuery,
  verifyAddress,
  getFileLabelAddress,
  getFileLabels,
  setFileLabelAddress,
  createHomePickupCommand: CommonCarrierService.createHomePickupCommand,
  getFileShippingsQuery,
  getGenericCarrierAccountsForUpsQuery,
  getGenericCarrierAccountsDefinitionsQuery,
  upsertGenericCarrierAccountForUpsCommand,
  listExternalCarrierCompanysQuery,
  getTransportPlanByIdQuery: CommonCarrierService.getTransportPlanByIdQuery,
};
