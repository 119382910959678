const geocodeByPlaceId = ({
  placeId,
}: {
  placeId: string;
}): Promise<google.maps.GeocoderResult[]> => {
  if (window.google && window.google.maps && window.google.maps.Geocoder) {
    const geocoder = new window.google.maps.Geocoder();

    return new Promise((resolve, reject) => {
      geocoder.geocode({ placeId }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          resolve(results || []);

          return;
        }
        if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          resolve([]);

          return;
        }
        reject(new Error(status));
      });
    });
  }

  return Promise.reject([]);
};

export default geocodeByPlaceId;
