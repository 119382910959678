import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  File,
} from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

import { DateTimeFilter, SearchFilterFacet } from './filterFilesCommand';

const ENDPOINT = `${APIConfiguration.search}filteredtodos`;

interface FilterTodosCommandArgs {
  query?: string;
  pageSize?: number;
  page?: number;
  isDescending?: boolean;
  sort?: string;
  filters?: SearchFilterFacet[];
  dateTimeFilters?: DateTimeFilter[];
}

async function filterTodosCommand({
  query,
  filters,
  page,
  pageSize,
  sort,
  isDescending,
  dateTimeFilters,
}: FilterTodosCommandArgs): Promise<
  BackResponse<SearchResultList<File>> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SearchResultList<File>>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        query,
        filters,
        page,
        pageSize,
        sort,
        isDescending,
        dateTimeFilters,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default filterTodosCommand;
