import styled from 'styled-components';

const Z_INDEX_GLOBAL_LOADER = 999;

export const $GlobalLoader = styled.span`
  position: fixed;
  bottom: 0.75rem;
  right: 1.5rem;
  z-index: ${Z_INDEX_GLOBAL_LOADER};
`;
