import React, { FC } from 'react';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import { SafeFormattedMessageWithoutSpread } from '@savgroup-front-common/core/src/formatters';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

import { ResultSearchType } from '../SearchWithDimmer.types';

import messages from './messages';
import NoSearchResult from './NoSearchResult';
import ResultHighlight from './ResultHighlight/ResultHighlight';
import ResultIcon from './ResultIcon/ResultIcon';
import {
  $Button,
  $SearchResultContent,
  $SearchResultIcon,
  $SearchResultValue,
  $SearchResultValueItem,
  $SearchResultWrapper,
} from './SearchResult.styles';
import { SearchResultHeader } from './SearchResultHeader/SearchResultHeader';

const SearchResult: FC<{
  results: SearchResultList<ResultSearchType>[];
  searchValue?: string;
  onShowMore?: () => void;
}> = ({ results, searchValue, onShowMore }) => {
  if (!results.length) {
    return <></>;
  }

  const filteredResults = results.filter((x) => !!x.hitCount);

  if (filteredResults.length === 0) {
    return <NoSearchResult />;
  }

  return (
    <$SearchResultWrapper>
      {filteredResults.map((result) => {
        const restCount = result.hitCount - (result.hits?.length || 0) > 0;

        return (
          <$SearchResultContent
            key={result.title?.defaultMessage || `${result.hitCount}`}
          >
            <SearchResultHeader
              iconColor={result.iconColor}
              title={result.title}
              count={result.hitCount}
            />

            <$SearchResultValue>
              {result.hits?.map((hit) => (
                <$SearchResultValueItem
                  key={hit.searchReference}
                  href={hit.searchUrl}
                  dataTestId="searchItemResultLink"
                >
                  <$SearchResultIcon $bgColor={result.iconColor?.bgColor}>
                    {result.icon && (
                      <ResultIcon
                        iconName={result.icon}
                        color={result.iconColor?.color}
                      />
                    )}
                  </$SearchResultIcon>
                  <ResultHighlight
                    searchValue={searchValue}
                    hit={hit}
                    color={result.iconColor?.color}
                  />
                </$SearchResultValueItem>
              ))}

              {restCount && (
                <$Button
                  naked
                  small
                  onClick={onShowMore}
                  type={BUTTON_TYPES.BUTTON}
                >
                  <SafeFormattedMessageWithoutSpread
                    message={messages.showMore}
                    values={{
                      value: result.hitCount - (result.hits?.length || 0),
                    }}
                  />
                </$Button>
              )}
            </$SearchResultValue>
          </$SearchResultContent>
        );
      })}
    </$SearchResultWrapper>
  );
};

export default SearchResult;
