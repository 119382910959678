/* eslint-disable react-hooks/exhaustive-deps */
import OT, {
  OTError,
  PublisherProperties,
  SubscriberProperties,
} from '@opentok/client';
import React, { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';

import {
  logError,
  logVerbose,
} from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { API_COMMON_ERROR } from '@savgroup-front-common/constants';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { useConfirmationContext } from '../../components/Confirmation/ConfirmationContextProvider';
import { safeFormattedIntlString } from '../../formatters';
import globalMessages from '../../helpers/i18n';
import { toast } from '../Toast';

import messages from './messages';
import { $SnapshotPreviewContainer } from './VideoCall.styles';
import { MainScreen } from './VideoCall.types';

export const useVideoCall = ({
  applicationId,
  token,
  sessionId,
  publisherPreference = {},
  subscriberPreference = {},
  main,
  uuid,
  onTakeSubscriberPhoto,
  onSessionEnd,
}: {
  applicationId: string;
  sessionId: string;
  token: string;
  publisherPreference?: {
    initials?: string;
    name?: string;
    publishAudio?: boolean;
    publishVideo?: boolean;
  };
  subscriberPreference?: {
    initials?: string;
    name?: string;
    publishAudio?: boolean;
    publishVideo?: boolean;
  };
  main: MainScreen;
  uuid: string;
  onSessionEnd?: () => void;
  onTakeSubscriberPhoto?: (
    file: File,
  ) => Promise<BackResponse<any> | BackResponseFailure>;
}) => {
  const { getConfirmation } = useConfirmationContext();
  const publisher = useRef<OT.Publisher>();
  const subscriber = useRef<OT.Subscriber>();
  const session = useRef<OT.Session>();

  const publisherOptions: PublisherProperties = {
    insertMode: 'append',
    width: main === MainScreen.PUBLISHER ? '100%' : '50px',
    height: main === MainScreen.PUBLISHER ? '100%' : '50px',
    facingMode: 'environment',
    mirror: false,
    resolution: '1920x1080',
    style: {
      buttonDisplayMode: 'on',
      backgroundImageURI: '',
    },
    ...publisherPreference,
  };

  const handleError = useCallback((error?: OTError) => {
    if (error) {
      toast.error(
        safeFormattedIntlString(
          globalMessages.apiCommonErrorMessages[API_COMMON_ERROR.UNHANDLED],
        ),
      );

      logError(error.message);
    }
  }, []);

  const handleInitializeSession = useCallback(() => {
    if (!applicationId || !sessionId || !token) {
      return undefined;
    }

    session.current = OT.initSession(applicationId, sessionId);

    session.current.on({
      connectionCreated: () => {
        logVerbose('connectionCreated');
      },
      sessionConnected: () => {
        logVerbose('sessionConnected');
      },
      sessionDisconnected: () => {
        logVerbose('sessionDisconnected');

        if (onSessionEnd) {
          onSessionEnd();
        }
      },
      sessionReconnected: () => {
        logVerbose('sessionReconnected');
      },
      sessionReconnecting: () => {
        logVerbose('sessionReconnecting');
      },
      streamDestroyed: () => {
        logVerbose('streamDestroyed');
      },
      streamCreated: async (event: any) => {
        logVerbose('streamCreated');

        const subscriberOptions: SubscriberProperties = {
          insertMode: 'append',
          width: main === MainScreen.SUBSCRIBER ? '100%' : '50px',
          height: main === MainScreen.SUBSCRIBER ? '100%' : '50px',
          style: {
            buttonDisplayMode: 'on',
            backgroundImageURI: undefined,
          },
          ...subscriberPreference,
        };

        if (!session.current) {
          return undefined;
        }

        subscriber.current = session.current.subscribe(
          event.stream,
          `subscriber_${uuid}`,
          subscriberOptions,
          handleError,
        );
        subscriber.current.on({
          disconnected() {
            logVerbose('Subscriber disconnected.');
          },
          connected() {
            logVerbose('Connected');
          },
          destroyed(event: any) {
            logVerbose('stream destroyed', event);

            event.preventDefault();
          },
        });

        return undefined;
      },
    });

    publisher.current = OT.initPublisher(
      `publisher_${uuid}`,
      publisherOptions,
      handleError,
    );

    session.current.connect(token, (error) => {
      if (error) {
        handleError(error);

        return undefined;
      }

      if (publisher.current && session.current) {
        session.current.publish(publisher.current, handleError);
      }

      return undefined;
    });

    return undefined;
  }, [applicationId, handleError, main, sessionId, token]);

  useEffect(() => {
    if (applicationId && token && sessionId) {
      handleInitializeSession();
    }

    return () => {
      if (publisher.current) {
        publisher.current.destroy();
      }

      if (session.current) {
        session.current.off();
        session.current.disconnect();
      }
    };
  }, [applicationId, handleInitializeSession, sessionId, token]);

  const {
    mutateAsync: handleTakeSubscriberPhoto,
    isLoading: isTakeSubscriberPhotoLoading,
  } = useMutation(['handleTakeSubscriberPhoto'], async () => {
    if (subscriber.current && onTakeSubscriberPhoto) {
      const base64Image = subscriber.current.getImgData() || undefined;

      if (base64Image) {
        const isConfirmed = await getConfirmation({
          title: messages.confirmationTakeSubscriberPhotoTitle,
          didactic: messages.confirmationTakeSubscriberPhotoDidactic,
          children: (
            <$SnapshotPreviewContainer>
              <img
                src={`data:image/png;base64, ${base64Image}`}
                alt="Subscriber"
              />
            </$SnapshotPreviewContainer>
          ),
        });

        if (isConfirmed) {
          const response = await onTakeSubscriberPhoto(
            new File(
              [
                Uint8Array.from(
                  atob(base64Image),
                  (m) => m.codePointAt(0) as number,
                ),
              ],
              `${safeFormattedIntlString(messages.photoFileName)}.png`,
              { type: 'image/png' },
            ),
          );

          if (response.failure) {
            toast.error(
              safeFormattedIntlString(messages.takeSubscriberPhotoError),
            );
          } else {
            toast.success(
              safeFormattedIntlString(messages.takeSubscriberPhotoSuccess),
            );
          }
        }
      }
    }
  });

  return {
    handleTakeSubscriberPhoto,
    isTakeSubscriberPhotoLoading,
  };
};
