import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { media } from '@savgroup-front-common/constants';
import { decelerateTimingFunctionAnimation } from '@savgroup-front-common/core/src/animations/timingFunction';
import { Button } from '@savgroup-front-common/core/src/atoms/button';
import {
  rem,
  styledGetCustomComponentProperty,
} from '@savgroup-front-common/core/src/helpers';
import {
  SUPPORTED_COMPONENTS,
  SUPPORTED_PROPERTIES,
} from '@savgroup-front-common/types';

import { SearchInputVariant } from './SearchInput.types';

export const $SearchIconContainer = styled.div<{ $isNewUiEnabled: boolean }>`
  position: absolute;
  left: 0.75rem;
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? '32px'
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })};
  display: flex;
  align-items: center;
`;
export const $SearchLoadingContainer = styled.div`
  position: absolute;
  top: ${rem(10)};
  left: ${rem(2)};
  border-radius: 50%;
  width: ${rem(15)};
  height: ${rem(15)};
`;

export const $SearchInputContainer = styled.div<{
  $variant: SearchInputVariant;
  $isNewUiEnabled: boolean;
  $isActive?: boolean;
  $isHightContrast?: boolean;
}>`
  position: relative;
  display: flex;
  width: 100%;
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? '32px'
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })};

  svg {
    color: ${({ $variant, theme, $isNewUiEnabled, $isHightContrast }) => {
      if ($isNewUiEnabled) {
        return $isHightContrast
          ? theme.colors.white
          : theme.colors.mainTextColor;
      }

      return $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.colors.white
        : null;
    }};
  }

  &:hover {
    svg {
      color: ${({ $variant, theme, $isHightContrast }) =>
        $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
          ? $isHightContrast
            ? theme.colors.white
            : theme.colors.mainTextColor
          : null};
    }
  }

  z-index: ${({ $isActive }) => ($isActive ? 12 : null)};
`;

export const $SearchClearButton = styled(Button)`
  opacity: 0;
  pointer-events: none;
  transition-duration: 0.3s;
  ${decelerateTimingFunctionAnimation};
  &:focus {
    opacity: 1;
    pointer-events: all;
  }
`;
export const $SearchActionContainer = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

const defaultVariant = css<{ $variant: SearchInputVariant }>`
  background: ${({ $variant, theme }) =>
    $variant === SearchInputVariant.DEFAULT
      ? theme.systemColors.defaultBackground
      : null};
  border-color: ${({ $variant, theme }) =>
    $variant === SearchInputVariant.DEFAULT ? theme.colors.default : null};

  &:focus {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.DEFAULT ? theme.colors.primary : null};
  }
  &:hover {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.DEFAULT ? theme.colors.primary : null};
    background: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.DEFAULT
        ? theme.systemColors.defaultBackground
        : null};
    box-shadow: ${({ theme }) =>
      `0px 0px 4px ${rgba(theme.colors.primary, 0.4)}`};
  }

  color: ${({ theme }) => theme.colors.mainTextColor};
`;

const globalSearchVariant = css<{ $variant: SearchInputVariant }>`
  background: ${({ theme, $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH
      ? theme.systemColors.defaultBackground
      : null};
  border-color: ${({ theme, $variant }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH
      ? theme.colors.default
      : null};

  &:focus {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH
        ? theme.colors.primary
        : null};
  }
  &:hover {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH
        ? theme.colors.primary
        : null};
    background: ${({ theme, $variant }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH
        ? theme.systemColors.defaultBackground
        : null};
  }
`;

const globalSearchNewUIVariant = css<{
  $variant: SearchInputVariant;
  $isHightContrast?: boolean;
}>`
  background: ${({ $variant, $isHightContrast }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
      ? $isHightContrast
        ? 'rgba(255, 255, 255, 0.15)'
        : 'rgba(255, 255, 255, 0)'
      : null};
  border-color: ${({ $variant, $isHightContrast }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
      ? $isHightContrast
        ? 'transparent'
        : null
      : null};
  color: ${({ $variant, theme, $isHightContrast }) =>
    $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
      ? $isHightContrast
        ? theme.colors.white
        : theme.colors.mainTextColor
      : null};

  &::placeholder {
    color: ${({ $variant, theme }) => {
      return $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.colors.white
        : null;
    }};
  }

  &:focus {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.newUI?.defaultColors?.secondary
        : null};
    box-shadow: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? `0px 0px 6px 0px ${theme.newUI?.defaultColors?.primary}`
        : null};
  }
  &:hover {
    border-color: ${({ $variant, theme }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? theme.newUI?.defaultColors?.secondary
        : null};
    color: ${({ theme, $variant, $isHightContrast }) =>
      $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
        ? $isHightContrast
          ? theme.colors.white
          : theme.colors.mainTextColor
        : null};
    &::placeholder {
      color: ${({ theme, $variant }) =>
        $variant === SearchInputVariant.GLOBAL_SEARCH_NEW_UI
          ? theme.colors.paragraphTextColor
          : null};
    }
  }
`;

export const $SearchInput = styled.input<{
  $variant: SearchInputVariant;
  $isNewUiEnabled: boolean;
  $isHightContrast?: boolean;
}>`
  border: 0;
  height: ${({ $isNewUiEnabled }) =>
    $isNewUiEnabled
      ? '32px'
      : styledGetCustomComponentProperty({
          componentName: SUPPORTED_COMPONENTS.DEFAULT_INPUTS,
          property: SUPPORTED_PROPERTIES.HEIGHT,
        })};
  &:not([value='']) {
    ~ * ${$SearchClearButton}, ~ ${$SearchClearButton} {
      opacity: 1;
      pointer-events: all;
    }
  }
  &[value=''] {
    ~ * ${$SearchClearButton}, ~ ${$SearchClearButton} {
      pointer-events: none;
    }
  }

  ${defaultVariant};
  ${globalSearchVariant};
  ${globalSearchNewUIVariant};

  border-radius: ${({ theme }) => theme.borders.radius};
  padding: 0 0.5rem 0 3rem;
  flex: 1;

  transition-duration: 0.3s;
  border-width: 1px;
  border-style: solid;

  ${decelerateTimingFunctionAnimation};

  &:hover,
  &:focus {
    outline: 0;
  }

  @media ${media.maxWidth.xs} {
    padding: 0 2.5rem 0 3rem;
  }
`;

export const $SearchFilter = styled.div`
  && {
    button {
      border: none;
      background: transparent;
      &:hover {
        border: none;
        background: transparent;
      }
    }
  }
`;
