import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BUTTON_TYPES } from '@savgroup-front-common/constants';
import {
  MenuHamburgerOpenIcon,
  ReversioIcon,
} from '@savgroup-front-common/core/src/protons/icons';

import { ROUTES } from '../../../../../../constants/routing';
import { $HeaderLogo } from '../../../Header.styles';
import { $MenuHamburger } from '../MinimalHeaderNewUi.styles';

import Menu from './Menu';
import { $Header, $MinimalHeaderSidebar } from './MinimalHeaderSidebar.styles';
import { $MenuWrapper } from './MinimalHeaderSidebar.styles';

interface MinimalHeaderSidebarProps {
  onClose: () => void;
}

export const MinimalHeaderSidebar: FC<MinimalHeaderSidebarProps> = ({
  onClose,
}) => {
  const theme = useTheme();
  const iconColor = theme.colors.white;

  return (
    <$MinimalHeaderSidebar>
      <$Header>
        <$MenuHamburger onClick={onClose} type={BUTTON_TYPES.BUTTON}>
          <MenuHamburgerOpenIcon color={iconColor} />
        </$MenuHamburger>

        <$HeaderLogo>
          <Link to={ROUTES.HOME}>
            <ReversioIcon color="#fff" />
          </Link>
        </$HeaderLogo>
      </$Header>

      <$MenuWrapper>
        <Menu />
      </$MenuWrapper>
    </$MinimalHeaderSidebar>
  );
};
