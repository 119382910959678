import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.search}export/jobs/${COMMON_QUERY_PARAMS.JOB_ID}`;

export enum EXPORT_JOB_STATUS {
  PENDING = 'Pending',
  DONE = 'Done',
  FAIL = 'Fail',
  DELETED = 'Deleted',
}

interface GetExportJobQueryResponseValue {
  status: EXPORT_JOB_STATUS;
  downloadToken?: string;
}

const getExportJobQuery = async ({
  jobId,
}: {
  jobId: string;
}): Promise<
  BackResponse<GetExportJobQueryResponseValue> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.JOB_ID, jobId);
    const response = await apiCall<
      BackResponse<GetExportJobQueryResponseValue>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getExportJobQuery;
