const mapClientIdByHostname: Record<string, string> = {
  'preprod-app.revers.io': 'preprod-app.revers.io',
  localhost: 'preprod-app.revers.io',
};

const AuthConfiguration = {
  authority: 'https://preprod-app-login.revers.io',
  clientId: 'preprod-app.revers.io',
  scope: 'openid email api',
};

const { hostname } = window.location;

AuthConfiguration.clientId =
  mapClientIdByHostname[hostname] || window.location.hostname;

export default AuthConfiguration;
