import React, { FC } from 'react';

import useGetMenuItems from './hooks/useGetMenuItems';
import { MenuListItem } from './MenuListItem/MenuListItem';

const Menu: FC = () => {
  const { menus } = useGetMenuItems();

  return (
    <>
      {menus.map((menu) => {
        return <MenuListItem key={menu.to} menu={menu} />;
      })}
    </>
  );
};

export default React.memo(Menu);
