import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.carrier}sellers/${COMMON_QUERY_PARAMS.SELLER_ID}/carriers/genericAccount/:carrierUniqueName`;

export interface GetGenericCarrierAccountsForUpsQueryReturnValues {
  carrier: string;
  clientId: string;
  clientSecret: string;
  relayPointDeposit: boolean;
  relayPointDepositCustomerType: string;
  onSiteCollection: boolean;
  onSiteCollectionCustomerType: string;
  shipping: boolean;
  shippingCustomerType: string;
  sellerId: string;
  accountName: string;
  region: string;
  useReverseAccount: boolean;
}

interface GetGenericCarrierAccountsForUpsQueryArgs {
  sellerId: string;
  carrierUniqueName: string;
}

const getGenericCarrierAccountsForUpsQuery = async ({
  sellerId,
  carrierUniqueName,
}: GetGenericCarrierAccountsForUpsQueryArgs): Promise<
  | BackResponse<GetGenericCarrierAccountsForUpsQueryReturnValues>
  | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(
      COMMON_QUERY_PARAMS.SELLER_ID,
      sellerId,
    ).replace(':carrierUniqueName', carrierUniqueName);

    const response = await apiCall<
      BackResponse<GetGenericCarrierAccountsForUpsQueryReturnValues>
    >(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getGenericCarrierAccountsForUpsQuery;
