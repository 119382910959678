import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

import { ImportLevel } from '../Upload/getAvailableImportTypesQuery';

interface ImportLevelConfiguration {
  item1: string;
  item2: ImportLevel;
}

const ENDPOINT = `${APIConfiguration.import}tenants/${COMMON_QUERY_PARAMS.TENANT_ID}/importLevel`;

const getImportLevelConfigurationQuery = async ({
  tenantId,
}: {
  tenantId: string;
}): Promise<BackResponse<ImportLevelConfiguration[]> | BackResponseFailure> => {
  try {
    const url = buildUrl(
      ENDPOINT.replace(COMMON_QUERY_PARAMS.TENANT_ID, tenantId),
    );

    return await apiCall<BackResponse<ImportLevelConfiguration[]>>(url, {
      method: SUPPORTED_METHODS.GET,
    });
  } catch (exception) {
    return prepareResponseFailure(exception);
  }
};

export default getImportLevelConfigurationQuery;
