import { rgba, setLightness } from 'polished';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  media,
  Z_INDEX_MENU,
} from '@savgroup-front-common/constants/src/shared';
import { decelerateTimingFunctionTransition } from '@savgroup-front-common/core/src/animations/timingFunction';
import { rem } from '@savgroup-front-common/core/src/helpers';

import { MENU_ORIENTATION } from './Menu.types';

const size = 48;
const smallSize = 50;

export const $MenuContainer = styled.nav`
  display: flex;
  &::-webkit-scrollbar {
    width: 0 !important;
  }

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  scrollbar-width: none;
  background-color: transparent;
  position: relative;
  z-index: ${Z_INDEX_MENU};
  min-width: ${rem(size)};

  flex-direction: row;
  justify-content: flex-start;

  @media ${media.maxWidth.xs} {
    max-width: ${rem(300)};
    position: absolute;
    right: 100%;
    width: 100%;
    height: calc(100% - ${rem(50)});
    transition-duration: 0.5s;
    transition-property: transform;
    ${decelerateTimingFunctionTransition};
  }

  margin-left: 2rem;
`;

export const $MenuCountBadge = styled.span.attrs({
  animationDuration: 400,
})<{ $count: number }>`
  position: absolute;
  top: ${rem(5)};
  pointer-events: none;
  user-select: none;
  right: -${rem(3)};
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${rem(4)};
  background: ${({ theme }) => theme.colors.alert};
  color: ${({ theme }) => theme.colors.white};
  line-height: ${rem(20)};
  font-size: ${rem(11)};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
  text-align: center;

  opacity: ${({ $count }) => ($count > 0 ? 1 : 0)}!important;
  transition-duration: 400ms;
  ${decelerateTimingFunctionTransition};

  @media ${media.minWidth.sm} {
    transform: translate(0.25rem, 0.25rem);
    z-index: 1;
    + svg {
      transform: translate(
        ${({ $count }) => ($count > 0 ? '-0.25rem' : '0')},
        ${({ $count }) => ($count > 0 ? '-0.25rem' : '0')}
      ) !important;
    }
  }

  @media ${media.maxWidth.xs} {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
`;

export const $MenuIconContainer = styled.div`
  text-align: center;
  @media ${media.minWidth.sm} {
    position: relative;
  }
`;

export const $MenuItemTitle = styled.span`
  font-size: ${rem(14)};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumBold};

  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.25rem;
  max-width: 100%;
  max-height: ${rem(12 * 2)};
  text-align: center;

  @media ${media.maxWidth.xs} {
    font-size: ${rem(14)};
    font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};
    padding-left: 1rem;
  }
`;

const activeStyle = css`
  ${decelerateTimingFunctionTransition};
  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};

    background-color: ${({ theme }) =>
      rgba(setLightness(0.7, theme.colors.darkBackground), 0.3)};
  }
`;

const badgeHover = css`
  &:hover ${$MenuIconContainer} {
    svg {
      transform: translate(0, 0) !important;
    }
    ${$MenuCountBadge} {
      opacity: 0 !important;
    }
  }
`;

export const $MainLink = styled(NavLink)<{ $isDarkModeEnabled?: boolean }>`
  display: flex;
  cursor: pointer;
  outline: none;
  flex-direction: column;
  text-decoration: inherit;
  align-items: center;
  justify-content: center;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  padding: 0 1rem;
  @media ${media.maxWidth.xs} {
    position: relative;
  }

  svg {
    ${decelerateTimingFunctionTransition};
    transition-duration: 300ms;
    transform: translate(0, 0);
  }

  border-left: ${rem(3)} solid transparent;
  border-right: ${rem(3)} solid transparent;
  height: ${rem(size)};
  min-height: ${rem(size)};

  @media ${media.maxWidth.xs} {
    flex-direction: row;
    align-items: start;
    justify-content: left;
    border-left-width: ${rem(18)};
    border-right-width: ${rem(18)};
    height: ${rem(smallSize)};
    min-height: ${rem(smallSize)};
  }

  ${activeStyle};
`;

export const $MenuList = styled.div`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  position: relative;

  height: ${rem(size)};

  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const $MenuItem = styled.div<{
  $hideCountOnHover: boolean;
  $isActive?: boolean;
  $orientation?: MENU_ORIENTATION;
}>`
  width: 100%;
  position: relative;
  ${({ $hideCountOnHover }) => ($hideCountOnHover ? badgeHover : null)};
  white-space: nowrap;

  background: ${({ $orientation, $isActive, theme }) => {
    if ($orientation === MENU_ORIENTATION.HORIZONTAL) {
      return undefined;
    }

    if ($isActive && $orientation === MENU_ORIENTATION.VERTICAL) {
      return theme.colors.primary;
    }

    return 'unset';
  }};

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.primary};

    height: ${rem(4)};
    width: 100%;

    left: 0;
    bottom: 0;
    transition-duration: 0.4s;
    transition-property: opacity;
    transition-timing-function: linear;
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
    @media ${media.maxWidth.xs} {
      height: ${rem(smallSize)};
    }
    ${decelerateTimingFunctionTransition};
    border-radius: 8px;
  }
`;
