import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
  FilterPreset,
  FilterScope,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}search/filter/currentUser`;

async function currentUserFiltersQuery({
  scope,
}: {
  scope: FilterScope;
}): Promise<BackResponse<FilterPreset[]> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<FilterPreset[]>>(
      buildUrl(ENDPOINT, { scope }),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default currentUserFiltersQuery;
