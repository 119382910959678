import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requestLines/order/bulk`;

async function bulkOrderSparePartsCommand({
  requestLineIds,
  externalOrderReference,
}: {
  requestLineIds: string[];
  externalOrderReference: string;
}): Promise<BackResponse<string> | BackResponseFailure> {
  try {
    const response = await apiCall<BackResponse<string>>(ENDPOINT, {
      method: SUPPORTED_METHODS.POST,
      json: {
        requestLineIds,
        externalOrderReference,
      },
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default bulkOrderSparePartsCommand;
