import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import React, { FC, PropsWithChildren } from 'react';

import { APIConfiguration } from '@savgroup-front-common/configuration';
import { logVerbose } from '@savgroup-front-common/configuration/src/appInsights/AppInsights';
import { selectLocale } from '@savgroup-front-common/core/src/domains/i18n/selectors';
import useCommonTypedSelector from '@savgroup-front-common/core/src/domains/useCommonTypedSelector';
import { userManager } from '@savgroup-front-common/core/src/services/userManager';

const httpLink = createHttpLink({
  uri: `${APIConfiguration.graphQlGateway}`,
});

const GraphqlProvider: FC<PropsWithChildren> = ({ children }) => {
  const locale = useCommonTypedSelector(selectLocale);

  const authLink = setContext(async () => {
    const user = await userManager.getUser();

    let accessToken = null;

    if (user && !user.expired) {
      accessToken = user.access_token;
    } else {
      logVerbose('User is not logged in');
    }

    return {
      headers: {
        'Accept-Language': locale,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphqlProvider;
