import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { FEATURE_FLAGS_FOR_SELLER } from '@savgroup-front-common/constants';
import { useGetFeatureFlagsQuery } from '@savgroup-front-common/core/src/api/Authorization/useGetFeatureFlagsQuery';
import { useNewUiContext } from '@savgroup-front-common/core/src/components/NewUiProvider/NewUiProvider';
import { ROUTES } from 'control/constants/routing';

import CreateButton from './CreateButton';
import {
  $AppHeaderContainer,
  $HeaderPortal,
  $SellerSelectPortal,
} from './Header.styles';
import { HeaderNewUi } from './HeaderNewUi/HeaderNewUi';
import Logo from './Logo';
import Profile from './Profile';
import Search from './Search';
import { SwitchNewBo } from './SwitchNewBo/SwitchNewBo';

interface HeaderProps {
  onLogoClick: () => void;
  isMenuOpen: boolean;
}

const Header: FC<React.PropsWithChildren<HeaderProps>> = ({
  onLogoClick,
  isMenuOpen,
}) => {
  const isConfigurationConsolePage = !!useMatch(ROUTES.CONFIGURATION_CONSOLE);

  const canSwitchToNewBo = useGetFeatureFlagsQuery({
    featureFlag: FEATURE_FLAGS_FOR_SELLER.SWITCH_NEW_BO,
  });
  const { isNewUiEnabled } = useNewUiContext();

  if (isNewUiEnabled) {
    return <HeaderNewUi />;
  }

  return (
    <$AppHeaderContainer $isNewUiEnabled={false}>
      <Logo onClick={onLogoClick} isMenuOpen={isMenuOpen} />
      {!isConfigurationConsolePage && (
        <>
          <CreateButton />

          <Search />
        </>
      )}
      {isConfigurationConsolePage && <$HeaderPortal id="headerPortalId" />}
      {isConfigurationConsolePage && (
        <$SellerSelectPortal id="headerSellerSelectPortalId" />
      )}
      {canSwitchToNewBo && !isConfigurationConsolePage && <SwitchNewBo />}
      <Profile />
    </$AppHeaderContainer>
  );
};

export default Header;
