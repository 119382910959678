import { darken, lighten } from 'polished';
import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { rem } from '@savgroup-front-common/core/src/helpers';

export const $ProfilButtonContainer = styled.div`
  position: relative;
  > div {
    margin-right: ${rem(2)};
  }

  margin-left: auto;
`;

export const $ProfilButtonNewUi = styled.button`
  outline: 0;
  border: none;
  height: 100%;

  display: flex;
  align-items: center;

  min-width: ${rem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  transition-property: background-color;
  &:focus,
  &:hover {
    background-color: unset;
  }

  position: relative;
  background-color: unset;

  font-weight: ${({ theme }) => theme.fonts.weight.bold};

  & > div > div {
    transition-duration: ${({ theme }) => theme.interactions.basicTiming};
    transition-property: background-color;
    background-color: ${({ theme }) =>
      theme.newUI.variationColors?.light5} !important;
    color: ${({ theme }) => theme.newUI.defaultColors.heading} !important;
  }
  &:hover > div > div {
    background-color: ${({ theme }) =>
      lighten(0.05, theme.newUI.variationColors?.light5)} !important;
    color: ${({ theme }) => theme.colors.headingTextColor} !important;
  }
`;

export const $ProfilButton = styled.button`
  outline: 0;
  border: none;
  min-width: ${rem(50)};
  height: ${rem(50)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.interactions.basicTiming};
  transition-property: background-color;
  &:focus,
  &:hover {
    background-color: ${({ theme }) => darken(0.15, theme.colors.primary)};
  }
  @media ${media.minWidth.lg} {
    min-width: ${rem(70)};
    height: ${rem(70)};
  }
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkBackground};
`;

export const $MenuVerticalPosition = styled.div<{ $isNewUiEnabled: boolean }>`
  ul {
    margin-top: ${({ $isNewUiEnabled }) => ($isNewUiEnabled ? '0' : null)};
  }
`;
