import React, { FC } from 'react';
import { useMatch } from 'react-router-dom';

import { $MenuItem } from '../Menu.styles';
import { MENU_ORIENTATION, MenuItem } from '../Menu.types';
import MenuLink from '../MenuLink/MenuLink';

interface MenuListItemProps {
  menu: MenuItem;
  orientation?: MENU_ORIENTATION;
}

export const MenuListItem: FC<MenuListItemProps> = ({
  menu,
  orientation = MENU_ORIENTATION.HORIZONTAL,
}) => {
  const isActive = useMatch(menu.path);

  return (
    <$MenuItem
      key={menu.to}
      $hideCountOnHover={menu.count === 0 && menu.subCount > 0}
      role="presentation"
      data-testid={`sidebar_menucontainer_${menu.dataTestId}`}
      $isActive={!!isActive}
      $orientation={orientation}
    >
      <MenuLink
        to={menu.to}
        dataTestId={`sidebar_menu_${menu.dataTestId}`}
        message={menu.message}
      />
    </$MenuItem>
  );
};
