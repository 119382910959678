import styled from 'styled-components';

import { Link } from '@savgroup-front-common/core/src/atoms/link';

export const $MenuNavigationItem = styled(Link)<{ $isActive: boolean }>`
  color: ${({ theme }) => theme.newUI.defaultColors.text};
  text-decoration: none;
  display: flex;
  width: 100%;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: ${({ theme }) => theme.newUI.borders.smallRadius};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.newUI.variationColors?.light5 : undefined};

  cursor: pointer;

  & svg,
  & path {
    stroke: ${({ theme }) => theme.newUI.iconColors.primary.color};
  }

  font-size: ${({ theme }) => theme.fonts.size.small};
  font-weight: ${({ theme }) => theme.fonts.weight.mediumFont};

  & *::first-letter {
    text-transform: uppercase;
  }

  &:hover {
    background-color: ${({ theme }) => theme.newUI.variationColors?.light4};
    text-decoration: none;
  }
`;

export const $Icon = styled.div`
  display: flex;
  align-items: center;
`;
