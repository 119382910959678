import { History } from 'history';
import { Store } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { history as commonHistory } from '@savgroup-front-common/configuration';
import { ControlRootState } from 'control/domains/ControlRootState';
import rootSaga from 'control/domains/rootSaga';

import configureStore from './configureStore';

export let store: Store<ControlRootState>;

export let history: History & {
  listenObject: boolean;
};

export const setStoreAndHistory = () => {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: commonHistory as any,
    });

  const configuredStore = configureStore({
    routerMiddleware,
    routerReducer,
  });

  store = configuredStore.store;
  configuredStore.runSaga(rootSaga);

  history = createReduxHistory(store) as any;

  return { store, history };
};
