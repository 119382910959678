import styled from 'styled-components';

import { media } from '@savgroup-front-common/constants/src/shared';
import { styledGetCustomComponentProperty } from '@savgroup-front-common/core/src/helpers';
import { SUPPORTED_PROPERTIES } from '@savgroup-front-common/types';

export const $PageContainerStyled = styled.div<{
  $componentThemeName?: string;
  $isNewUiEnabled?: boolean;
}>`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 0.5rem;
  position: relative;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  background: ${({ theme, $componentThemeName, $isNewUiEnabled }) => {
    if ($isNewUiEnabled) {
      return null;
    }

    if ($componentThemeName) {
      return styledGetCustomComponentProperty({
        componentName: $componentThemeName,
        property: SUPPORTED_PROPERTIES.BACKGROUND_COLOR,
      });
    }

    return theme.colors.secondaryColor2;
  }};
  @media ${media.minWidth.sm} {
    padding: 1rem 1.2rem;
  }
`;
