import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared/supportedMethods';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.search}export/file`;

const getExportedFileQuery = async ({
  token,
}: {
  token: string;
}): Promise<BackResponse<any> | BackResponseFailure> => {
  try {
    const url = buildUrl(ENDPOINT, {
      token,
    });
    const response = await apiCall<BackResponse<any>>(url, {
      method: SUPPORTED_METHODS.GET,
    });

    return response;
  } catch (error) {
    return prepareResponseFailure(error);
  }
};

export default getExportedFileQuery;
