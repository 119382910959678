import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { COMMON_QUERY_PARAMS } from '@savgroup-front-common/constants';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import {
  buildUrl,
  prepareResponseFailure,
} from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  DeliveryLocationType,
  FileProductSummary,
  RequestLineStatus,
  RequestReliability,
  ReverseMoney,
  SparePartDto,
  SparePartSupplierAssociationDto,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.sparePart}requests/line/infos/${COMMON_QUERY_PARAMS.ID}`;

export interface GetRequestLineSummaryQueryArgs {
  id: string;
}

export interface SparePartRequestLineNewModelSummaryDto {
  requestLineId: string;
  fileId?: string;
  fileReference?: string;
  fileProduct: FileProductSummary;
  sellerId: string;
  sellerName: string;
  creationDate: string;
  label: string;
  deliveryLocationType: DeliveryLocationType;
  deliveryLocationAddressInfo: AddressInfoDto;
  intermediateDeliveryLocationType: DeliveryLocationType;
  intermediateDeliveryLocationAddressInfo?: AddressInfoDto;
  purchasePrice?: ReverseMoney;
  sellingPrice?: ReverseMoney;
  reliability: RequestReliability;
  status: RequestLineStatus;
  techComment: string;
  factoryCode?: string;
  requesterName: string;
  serialNumber?: string;
  additionalFiles?: string[];
  namePlate?: string;
  sparePart: SparePartDto;
  association: SparePartSupplierAssociationDto;

  orderReference?: string;
  orderSubmittedToSupplierDate?: string;
  orderRejectionReason?: string;
  stockItemId?: string;
}

export default async function getRequestLineSummaryQuery({
  id,
}: GetRequestLineSummaryQueryArgs): Promise<
  BackResponse<SparePartRequestLineNewModelSummaryDto> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SparePartRequestLineNewModelSummaryDto>>(
      buildUrl(ENDPOINT.replace(COMMON_QUERY_PARAMS.ID, id)),
      {
        method: SUPPORTED_METHODS.GET,
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}
