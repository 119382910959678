import APIConfiguration from '@savgroup-front-common/configuration/src/api';
import { SUPPORTED_METHODS } from '@savgroup-front-common/constants/src/shared';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  AddressInfoDto,
  BackResponse,
  BackResponseFailure,
  DeliveryLocationType,
  RequestLineStatus,
  RequestReliability,
  ReverseMoneyNumberAmount,
  SortOption,
} from '@savgroup-front-common/types';
import { SearchResultList } from '@savgroup-front-common/types/src/SearchResultList';

import {
  DateTimeFilter,
  SearchFilterFacet,
} from '../Search/filterFilesCommand';

const ENDPOINT = `${APIConfiguration.sparePart}dispatch/search`;

export interface SearchDispatchRequestLineDto {
  requestLineId: string;
  creationDate: Date;
  label: string;
  manufacturerName: string;
  manufacturerReference: string;
  supplierReference?: string;
  supplierName: string;
  deliveryLocationType: DeliveryLocationType;
  deliveryLocationName: string;
  purchasePrice?: ReverseMoneyNumberAmount;
  sellingPrice?: ReverseMoneyNumberAmount;
  reliability: RequestReliability;
  status: RequestLineStatus;
  requester: string;
  possibleNextStatuses: RequestLineStatus[];
  orderReference?: string;
  orderSubmittedToSupplierDate?: Date;
  orderRejectionReason?: string;
  stockItemId?: string;
  locationInStock?: string;
  fileId?: string;
  fileReference?: string;
}

export interface SearchDispatchHitDto {
  id: string;
  orderNumber: string;
  orderDate: Date;
  deliveryLocation: AddressInfoDto;
  deliveryLocationFilter: string;
  orderedByUser: string;
  totalAmount: {
    preTaxAmount: ReverseMoneyNumberAmount;
    taxRate: number;
    amountIncludedTax: ReverseMoneyNumberAmount;
    taxAmount: ReverseMoneyNumberAmount;
  };
  paymentTerms: string;
  customInstructions: string;
  supplierName: string;
  isAutomatic: boolean;
  trackingNumbers: string[];
  deliveryNotes: string;
  additionalDocuments: string[];
  clientId: string;
  status: RequestLineStatus;
  clientName: string;
  requestLines: SearchDispatchRequestLineDto[];
  requestLinesCount: number;
}

interface SearchDispatchesCommandPayload {
  query: string;
  page: number;
  pageSize: number;
  sortOptions?: SortOption[];
  isDescending?: boolean;
  sort?: string;
  filters?: SearchFilterFacet[];
  dateTimeFilters?: DateTimeFilter[];
  searchSupplierDispatches: boolean;
}

async function searchDispatchesCommand({
  page,
  pageSize,
  sortOptions = [],
  sort,
  isDescending,
  query,
  filters = [],
  dateTimeFilters = [],
  searchSupplierDispatches,
}: SearchDispatchesCommandPayload): Promise<
  BackResponse<SearchResultList<SearchDispatchHitDto>> | BackResponseFailure
> {
  try {
    return await apiCall<BackResponse<SearchResultList<SearchDispatchHitDto>>>(
      ENDPOINT,
      {
        method: SUPPORTED_METHODS.POST,
        json: {
          page,
          pageSize,
          sortOptions,
          sort,
          isDescending,
          query,
          filters,
          dateTimeFilters,
          searchSupplierDispatches,
        },
      },
    );
  } catch (error) {
    return prepareResponseFailure(error);
  }
}

export default searchDispatchesCommand;
