import { APIConfiguration } from '@savgroup-front-common/configuration';
import {
  COMMON_QUERY_PARAMS,
  SUPPORTED_METHODS,
} from '@savgroup-front-common/constants';
import { prepareResponseFailure } from '@savgroup-front-common/core/src/helpers';
import { apiCall } from '@savgroup-front-common/core/src/services';
import {
  BackResponse,
  BackResponseFailure,
} from '@savgroup-front-common/types';

const ENDPOINT = `${APIConfiguration.payment}SellerPricingPlanBillingConfiguration`;

export interface CreateSellerPricingPlanBillingConfigurationCommandArgs {
  sellerId: string;
}

export const createSellerPricingPlanBillingConfigurationCommand = async ({
  sellerId,
}: CreateSellerPricingPlanBillingConfigurationCommandArgs): Promise<
  BackResponse<string> | BackResponseFailure
> => {
  try {
    const url = ENDPOINT.replace(COMMON_QUERY_PARAMS.SELLER_ID, sellerId);

    return await apiCall<BackResponse<string>>(url, {
      method: SUPPORTED_METHODS.POST,
      json: {
        sellerId,
      },
    });
  } catch (error) {
    return prepareResponseFailure(error);
  }
};
